import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";

type AccordionType = 'single' | 'multiple';

interface AccordionContextInterface {
  type: AccordionType;
  activeKey: string[];
  setActiveKey: React.Dispatch<React.SetStateAction<string[]>>;
  // collapsible: boolean;
  disabled: boolean,
  // orientation: "horizontal" | "vertical"
  toggleCollpase: (value: string) => void;
}

type BaseInterface = React.HTMLAttributes<HTMLDivElement>;

interface AccordionInterface extends BaseInterface {
  type?: 'single' | 'multiple';
  defaultValue?: string;
  disabled?: boolean;
}

interface AccordionItemInterface extends BaseInterface {
  value: string;
  disabled?: boolean;
}

interface AccordionHeaderInterface extends BaseInterface {
  value?: string;
}

interface AccordionContentInterface extends BaseInterface {
  value?: string;
}

const AccordionContext = createContext<AccordionContextInterface | null>(null)

const useAccordionContext = () => {
  const accordionContext = useContext(AccordionContext);
  if (!accordionContext) {
    throw new Error("useAccordionContext has to be within Accordion Provider");
  }
  return accordionContext;
}

// https://www.w3.org/WAI/ARIA/apg/patterns/accordion/examples/accordion/
export const Accordion: React.FC<AccordionInterface> = ({
  children,
  type = "multiple",
  className,
  defaultValue = "",
  disabled = false,
  ...rest
}) => {
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const toggleCollpase = useCallback((value: string) => {
    const isActive = activeKey.includes(value);
    if (isActive) {
      setActiveKey(activeKey => activeKey.filter(item => item !== value));
    } else {
      if (type === 'single') {
        setActiveKey([value]);
      } else {
        setActiveKey(item => [...item, value]);
      }
    }
  }, [activeKey, type])

  return (
    <AccordionContext.Provider
      value={{ activeKey, setActiveKey, type, disabled, toggleCollpase }}>
      <div
        className={`${className} accordion`}
        {...rest}
      >
        {children}
      </div>
    </AccordionContext.Provider>
  );
}


interface AccordionItemContextInterface {
  value: string;
  disabled: boolean;
  isActive: boolean;
}
const AccordionItemContext = createContext<AccordionItemContextInterface | null>(null)

const useAccordionItemContext = () => {
  const accordionItemContext = useContext(AccordionItemContext);
  if (!accordionItemContext) {
    throw new Error("useAccordionItemContext has to be within AccordionItem Provider");
  }
  return accordionItemContext;
}

export const AccordionItem: React.FC<AccordionItemInterface> = ({ children, value, disabled = false, ...rest }) => {
  const { activeKey } = useAccordionContext();
  const isActive = activeKey.includes(value);
  return (
    <AccordionItemContext.Provider value={{ value, disabled, isActive }}>
      <div
        className="accordion-item"
        {...rest}
      >
        {children}
      </div>
    </AccordionItemContext.Provider>
  );
}

export const AccordionHeader: React.FC<AccordionHeaderInterface> = ({ children, style, ...rest }) => {
  const { disabled, toggleCollpase } = useAccordionContext();
  const { value, disabled: itemDisabled, isActive } = useAccordionItemContext()
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      toggleCollpase(value as string);
    }
  }
  const enabled = !disabled && !itemDisabled;

  return (
    <div
      className="accordion-header"
      role="button"
      tabIndex={0}
      onClick={() => enabled && toggleCollpase(value as string)}
      onKeyDown={handleKeyDown}
      aria-expanded={isActive}
      aria-controls={"accordion-" + value + "-item"}
      id={"accordion-" + value + "-trigger"}
      aria-disabled={disabled || itemDisabled}
      {...rest}
    >
      {children}
    </div>
  )
}

export const AccordionContent: React.FC<AccordionContentInterface> = ({ children, style, ...rest }) => {
  const { value, isActive } = useAccordionItemContext()
  const contentRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState("0px");

  useEffect(() => {
    if (contentRef.current) {
      if (isActive) {
        const contentHeight = contentRef.current.scrollHeight;
        setHeight(`${contentHeight}px`);
      } else {
        setHeight("0px");
      }
    }
  }, [isActive]);

  return (
    <div
      ref={contentRef}
      className={"accordion-content "}
      id={"accordion-" + value + "-item"}
      aria-labelledby={"accordion-" + value + "-trigger"}
      role="region"
      style={{
        height,
        ...style
      }}
      {...rest}
    >
      {isActive ? children : null}
    </div>
  )
}
