import { getPaginatedNotes } from "@crm/services.api";
import { PaginationMeta } from "@model/api-response.model";
import { Note, NoteType } from "@model/crm/note.model";
import { useInfiniteQuery } from "@tanstack/react-query";


interface Props {
  type?: NoteType;
  refId?: string;
  assignees?: string[];
  createdById?: string;
  limit?: number;
}

function getColName(type?: NoteType): string {
  switch (type) {
    case 'application':
      return 'applicationId';

    case 'contact':
      return 'contactId';

    case 'deal':
      return 'dealId';

    case 'enquiry':
      return 'contactEnquiryId';

    case 'officeVisit':
      return 'officeVisitId';
    default:
      throw new Error('Invalid note type')
  }
}
const usePaginateNotes = ({ type, refId, assignees, createdById, limit = 10 }: Props) => {
  const colName = getColName(type);
  const { data, fetchNextPage, isLoading, hasNextPage, refetch } = useInfiniteQuery<{ data: Note[], meta: PaginationMeta }>({
    queryKey: ['notes', type, refId, assignees],
    cacheTime: 0,
    queryFn: async (args) => {
      const params = {
        page: args.pageParam || 1,
        ...((refId && colName) && { [`filter.${colName}`]: '$eq:' + refId }),
        ...(createdById && { 'filter.createdById': '$eq:' + createdById }),
        limit,
        ...(assignees?.length && { 'filter.assignees': assignees }),
      };

      return (await getPaginatedNotes(params, type)).data;
    },
    getNextPageParam: (lastPage) => lastPage.meta.currentPage === lastPage.meta.totalPages ? false : lastPage.meta.currentPage + 1,

  })

  return {
    notes: data?.pages.map(paginatedNotes => paginatedNotes.data).flat() ?? [],
    next: fetchNextPage,
    isLoading,
    hasMore: hasNextPage,
    refetch,
  }
}

export default usePaginateNotes;
