import Cookies from 'js-cookie';
import {
  errorNotificationHandler,
  warnNotificationHandler,
} from '@moxie/shared';
import {
  COOKIE_ACCESS,
  COOKIE_REFRESH,
  AUTH_MESSAGE,
  ADMIN_COOKIE_ACCESS,
  ADMIN_COOKIE_REFRESH,
  CRM_COOKIE_ACCESS,
  CRM_COOKIE_REFRESH,
  CRM_EMAIL_LABEL,
} from '@moxie/constants';
import * as Sentry from '@sentry/react';

const getUrlFirstValue = () => {
  return window.location.pathname.split('/')[1]

}
// TODO: Refactor the handler based on crm-api
const crmErrorHandler = (err: any, showToast = true): void => {

  if (err.response?.data === undefined && !err) {
    Sentry.captureException(err);
    Sentry.showReportDialog();
    const email = localStorage.getItem(CRM_EMAIL_LABEL);
    if (email) {
      Sentry.setUser({
        email,
      })
    }
    errorNotificationHandler(AUTH_MESSAGE.SOMETHING_FAILED);
  }

  function unCaughtOrMultipleErrors(showToast: boolean) {
    if (!showToast) {
      return errorNotificationHandler(err.name + ': ' + err.message || err.response.data?.error, 5);
    }
    if (err?.response?.data && typeof err?.response?.data === 'object') {
      const keys = Object.keys(err.response.data);
      if (keys.includes('error')) {
        if (Array.isArray(err.response.data?.error)) {
          return err.response.data?.error.map((msg: string) =>
            errorNotificationHandler(msg)
          );
        }
        if (err?.response?.data?.error) {
          return errorNotificationHandler(
            err.response.data?.error || err.response.data?.detail || err.response.data?.message,
            5
          );
        }
      }
      return errorNotificationHandler(
        err.response.data?.message || err.message || err.response.data.error,
        5
      );
    }
  }
  function handleErrorBasedOnStatusCode(type: number, showToast: boolean) {
    switch (type) {
      case 401:
        Cookies.remove(COOKIE_REFRESH);
        Cookies.remove(COOKIE_ACCESS);
        Cookies.remove(CRM_COOKIE_ACCESS);
        Cookies.remove(CRM_COOKIE_REFRESH);
        Cookies.remove(ADMIN_COOKIE_ACCESS);
        Cookies.remove(ADMIN_COOKIE_REFRESH);
        showToast && errorNotificationHandler(err.response.data?.error);
        break;
      case 400:
        // eslint-disable-next-line no-case-declarations
        let message = err.response.data?.error || err.response.data?.message;
        if (message === 'Validation failed (uuid is expected)') {

          message = `Invalid ${getUrlFirstValue()} ID`
        }
        errorNotificationHandler(
          message,
          5
        );
        break;
      case 403:
        warnNotificationHandler(
          err.response.data?.error || err.response.data?.message
        );
        break;
      default:
        unCaughtOrMultipleErrors(showToast);
    }
  }
  const responseStatus =
    err.response?.data?.status || err.response?.data?.statusCode;
  if (responseStatus !== null || responseStatus !== undefined) {
    handleErrorBasedOnStatusCode(responseStatus, showToast);
    return;
  }
  unCaughtOrMultipleErrors(showToast);
};

export { crmErrorHandler };
