import React, { useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import usePaginateTasks from '../hooks/usePaginatedTasks';
import Divider from 'antd/es/divider';
import Spin from 'antd/es/spin';
import { List } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { Note, TaskPayload, TaskStatusFilter } from '@model/crm/note.model';
import NoteModal from '@crm/src/shared/note/note-modal';
import { groupBy } from '@crm/libs/helpers';
import { useUpdateNote } from '@crm/src/shared/note/hooks';
import TaskListItem from './task-list-item';
import NoteFormProvider from '@crm/src/shared/note/note-form-provider';
import difference from 'lodash/difference';


interface Props {
  scrollableDivId: string;
  startDate?: string;
  endDate?: string;
  createdById?: string;
  assignees?: string[];
  status: TaskStatusFilter;
}

const ScrollableTasks: React.FC<Props> = ({ scrollableDivId, startDate, endDate, assignees, createdById, status }) => {
  const queryClient = useQueryClient();

  const { hasMore, isLoading, next, tasks } = usePaginateTasks({
    startDate,
    endDate,
    assignees,
    createdById,
    status,
  })

  const [taskRef, setTaskRef] = useState<string | undefined>(undefined);
  const taskMap = groupBy(tasks, task => task.id)

  const updateNoteMutation = useUpdateNote();

  const updateNote = async (description: string, updatedAssignees: string[], task?: TaskPayload) => {

    if (note) {
      const assignees = note.assignees?.map(user => user.id);
      const removeAssignees = difference(assignees, updatedAssignees);
      await updateNoteMutation.mutateAsync({
        updatedField: "task",
        noteId: note.id,
        assignees: updatedAssignees,
        description,
        removeAssignees,
        task: task,
      })
      queryClient.invalidateQueries({
        queryKey: ['tasks'],
      });
      setTaskRef(undefined);
    }
  }

  const onModalClose = () => setTaskRef(undefined);

  const note = useMemo(() => {
    if (taskRef) {
      return {
        ...taskMap[taskRef].note,
        task: taskMap[taskRef],
      } as Note
    }
    return undefined;
  }, [taskRef, taskMap]);

  return (
    <NoteFormProvider>
      <div id={scrollableDivId} className="scrollable-tasks">
        <InfiniteScroll
          hasMore={hasMore}
          next={() => next()}
          dataLength={tasks.length}
          loader={isLoading ? <Spin /> : null}
          scrollableTarget={scrollableDivId}
          className="scrollable-tasks"
          endMessage={
            tasks.length > 10 ? (
              <Divider plain>You are all caught up</Divider>
            ) : null
          }
        >
          <List
            dataSource={tasks}
            renderItem={(task) => (
              <TaskListItem
                task={task}
                onClick={(val) => setTaskRef(val)}
              />
            )}
          />
        </InfiniteScroll>
      </div>
      <NoteModal
        visible={!!taskRef}
        note={note}
        onClose={onModalClose}
        onSubmit={updateNote}
        title="Update note"
      />
    </NoteFormProvider>
  )
}

export default ScrollableTasks
