import { IGetPopupContainer } from "@model/index"
import { LABEL } from "@moxie/constants"
import { FormInstance } from "antd"
import Form from "antd/es/form"
import { Col } from "antd/es/grid"
import InputNumber from "antd/es/input-number"
import Row from "antd/es/row"
import Select from "antd/es/select"
import Typography from "antd/es/typography"
import { useState } from "react"

const EnglishTestLists = ['IELTS', 'PTE', 'TOEFL', 'Duolingo', 'Not Taken'];
type TestType = 'IELTS' | 'PTE' | 'TOEFL' | 'Duolingo' | 'Not Taken';

interface IProps {
  form: FormInstance;
  hideTitle?: boolean;
  disabled?: boolean;
  enquiryForm?: boolean;
}

const maxValue = (test: TestType) => {
  switch (test) {
    case 'IELTS':
      return 9;
    case 'PTE':
      return 90;
    case 'TOEFL':
      return 30;
    case 'Duolingo':
      return 160;
  }
  return;
}

const maxOverallScore = (test: TestType) => {
  switch (test) {
    case 'IELTS':
      return 9;
    case 'PTE':
      return 90;
    case 'TOEFL':
      return 120;
    case 'Duolingo':
      return 160;
  }
  return;
}


export const LanguageTestScores = ({ form, hideTitle = false, disabled = false, enquiryForm = false }: IProps) => {
  const formTestValue = form.getFieldValue(['interestedService', 'test']);
  const [selectedTest, setSelectedTest] = useState<TestType>(formTestValue ?? 'Not Taken');

  return (
    <>
      <Row className="margin-bottom-2">
        <Typography.Text
          strong
          className={`${enquiryForm ? 'font-regular font-bold margin-top-1' : 'enquiry-form__additional-info__title'}`}
        >
          Language Test Scores
        </Typography.Text>
      </Row>

      <div id="office-vist-form__test-list" className="relative">
        <Form.Item
          name={['interestedService', 'test']}
          label="Language Test"
          initialValue={selectedTest}
        >
          <Select
            placeholder="Please select language test"
            showArrow
            disabled={disabled}
            getPopupContainer={(): IGetPopupContainer => document.getElementById('office-vist-form__test-list')}
            onChange={(value: TestType) => {
              setSelectedTest(value)
              form.setFieldsValue({
                interestedService: {
                  testScore: {
                    reading: undefined,
                    writing: undefined,
                    speaking: undefined,
                    listening: undefined,
                    overallScore: undefined
                  }
                }
              })
            }}
          >
            {EnglishTestLists?.map((test, index) => (
              <Select.Option key={index} value={test}>
                {test}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      {selectedTest !== "Not Taken" ?
        (
          <>
            <Row gutter={32}>
              <Col span={12}>
                <Form.Item
                  name={["interestedService", "testScore", "reading"]}
                  label={LABEL.READING}
                  rules={[{ type: 'number', min: selectedTest === 'Duolingo' ? 10 : 1, max: maxValue(selectedTest) }]}
                >
                  <InputNumber readOnly={disabled} className="full-width" data-testid="reading" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name={["interestedService", "testScore", "listening"]}
                  label={LABEL.LISTENING}
                  rules={[{ type: 'number', min: selectedTest === 'Duolingo' ? 10 : 1, max: maxValue(selectedTest) }]}
                >
                  <InputNumber readOnly={disabled} className="full-width" data-testid="listening" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={12}>
                <Form.Item
                  name={["interestedService", "testScore", "writing"]}
                  label={LABEL.WRITING}
                  rules={[{ type: 'number', min: selectedTest === 'Duolingo' ? 10 : 1, max: maxValue(selectedTest) }]}
                >
                  <InputNumber readOnly={disabled} className="full-width" data-testid="writing" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name={["interestedService", "testScore", "speaking"]}
                  label={LABEL.SPEAKING}
                  rules={[{ type: 'number', min: selectedTest === 'Duolingo' ? 10 : 1, max: maxValue(selectedTest) }]}
                >
                  <InputNumber readOnly={disabled} className="full-width" data-testid="speaking" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name={["interestedService", "testScore", "overallScore"]}
              label={LABEL.OVERALL_SCORE}
              rules={[
                {
                  type: 'number',
                  min: selectedTest === 'Duolingo' ? 10 : 1,
                  max: maxOverallScore(selectedTest),
                },
                {
                  required: true,
                },
              ]}
            >
              <InputNumber readOnly={disabled} className="full-width" data-testid="overall_score" />
            </Form.Item>
          </>
        )
        : null}

    </>
  )
}
