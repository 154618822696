/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Collapse,
  Form,
  Input,
  Row,
  Col,
  Divider,
  Button,
  Select,
  Upload,
  Typography,
  Spin,
  Skeleton,
  Radio,
  FormItemProps,
} from 'antd';
import {
  CaretRightOutlined,
  DeleteOutlined,
  PlusOutlined,
  BankOutlined
} from '@ant-design/icons';
import {
  AUTH_MESSAGE,
  COUNTRY_CURRENCY,
  DELETE_BTN,
  GUTTER,
  LABEL,
  MAIN_BRANCH,
  PHONE_NUMBER_INVALID,
  regex,
  TEXT,
  TITLE,
} from '@moxie/constants';
import {
  getInstituteTypes,
  fetchServiceCountry,
  getServices,
  getWorkflow,
} from '@crm/services.api';
import {
  ICRMInstitutionForm,
  IGetPopupContainer,
  IServices,
} from '@shared-components/models';
import { useAppSelector } from '@crm/core';
import { SearchableSelect } from '@shared-components/elements';
import { errorHandler } from '@moxie/utils';
import { capitalizeTrimWord } from '@moxie/shared';
const { Panel } = Collapse;
const { Option } = Select;

const InstitutionForm: React.FC<ICRMInstitutionForm> = ({
  form,
  submitFormData,
  setSubmitDisabled,
  isSubmitting,
  isEdit,
}: ICRMInstitutionForm) => {
  const [servicesData, setServicesData] = useState<IServices[]>([]);
  const [serviceWorkflow, setServiceWorkflow] = useState<any>([]);
  const [instituteType, setInstituteType] = useState<any>([]);
  const [serviceId, setServiceId] = useState<string>();
  const [workflowCountry, setWorkflowCountry] = useState<any>();
  const [countryList, setCountryList] = useState<any[]>([]);
  const [serviceLoading, setServiceLoading] = useState<boolean>(false);
  const [typeLoading, setTypeLoading] = useState<boolean>(false);
  const [workflowLoading, setWorkflowLoading] = useState<boolean>(false);

  const { institutionData, instituteLoading } = useAppSelector((state) => ({
    institutionData: state.institutions.institution,
    instituteLoading: state.institutions.loading,
  }));

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setSubmitDisabled(false) : setSubmitDisabled(true);
      }
    }
  };

  const handleFormDataSubmit = async (formData: any) => {
    const data = formData;
    let submitData;

    data.email =
      data?.branches?.find((d: any) => d.isMainBranch)?.email || '';

    data.name = capitalizeTrimWord(data?.name);

    if (institutionData?.id) {
      if (data?.name.toLowerCase() === institutionData?.name.toLowerCase()) {
        delete data?.name;
        submitData = { ...data, id: institutionData.id };
      }
      submitData = { ...data, id: institutionData.id };
      submitFormData(submitData);
    } else {
      submitFormData(data);
    }
  };

  const handleServiceChange = async (id: string) => {
    setServiceId(id);
    form.setFieldsValue({
      country: undefined,
      workflows: undefined,
    });
  };

  const fetchCountries = async (id: string) => {
    const countries = await fetchServiceCountry(id);
    setCountryList(countries?.data);
  };

  const handleCountryChange = async (name: any) => {
    setWorkflowCountry(name);
    form.setFieldsValue({
      workflows: undefined,
    });
  };

  const getAllInstituteTypes = async () => {
    setTypeLoading(true);
    const instituteTypes = await getInstituteTypes();
    setInstituteType(instituteTypes.data.data);
    setTypeLoading(false);
  };

  const fetchServices = async () => {
    setServiceLoading(true);
    const data = await getServices();
    if (data?.data?.data) {
      setServicesData(data?.data?.data);
      setServiceLoading(false);
    } else {
      errorHandler(AUTH_MESSAGE.FETCH_SERVICE_FAIL);
    }
  };
  const getWorkflows = async () => {
    setWorkflowLoading(true);
    if (serviceId && workflowCountry) {
      const response = await getWorkflow(serviceId, workflowCountry);
      setServiceWorkflow(response?.data?.data);
    }
    setWorkflowLoading(false);
  };

  const addNewBranch = async (add: any) => {
    add();
  };

  const sortedCurrency = COUNTRY_CURRENCY.sort((a, b) => a.name.localeCompare(b.name));

  const isMainBranch = (name: number) => {
    const { branches } = form.getFieldsValue();
    if (Array.isArray(branches) && !(branches.length === 0)) {
      if (branches[name]?.isMainBranch) {
        return true;
      } else {
        return false;
      }
    }
    return;
  };

  const handleBranchChange = (name: number) => {
    const { branches } = form.getFieldsValue();
    branches.forEach((branch: any, i: any) => {
      if (i !== name) {
        Object.assign(branch, { isMainBranch: false });
      } else {
        Object.assign(branch, { isMainBranch: true });
      }
    });
    setTimeout(() => {
      form.setFieldsValue({ branches });
    }, 0);
  };

  useEffect(() => {
    getWorkflows();
    if (serviceId) {
      fetchCountries(serviceId);
    }
  }, [serviceId, workflowCountry]);

  useEffect(() => {
    servicesData.length === 0 && fetchServices();
    if (institutionData && isEdit) {
      setServiceId(institutionData?.serviceId);
      setWorkflowCountry(institutionData?.country);
      setServiceWorkflow(() =>
        institutionData?.workflows?.map((item: any) => {
          return item;
        })
      );
      form.setFieldsValue({
        ...institutionData,
        workflows: institutionData?.workflows?.map((item: any) => item?.id),
      });
    } else {
      form.setFieldsValue({});
    }
  }, [institutionData]);

  useEffect(() => {
    fetchServices();
    getAllInstituteTypes();
  }, []);

  return isEdit && instituteLoading ? (
    <Skeleton active />
  ) : (
    <>
      <Spin spinning={serviceLoading || isSubmitting}>
        <Form
          layout="vertical"
          className="institution-add-form"
          form={form}
          onFinish={handleFormDataSubmit}
          scrollToFirstError={true}
          onFieldsChange={onFieldsChange}
        >
          <Collapse
            defaultActiveKey={['1']}
            bordered={false}
            ghost={true}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel header={<span>{TEXT.PRIMARY_INFORMATION}</span>} key="1">
              <Row gutter={GUTTER}>
                <Col
                  span={7}
                  className="text-align-center institution-add-form__avatar"
                >
                  {institutionData?.logo_path ? (
                    <img
                      className="institution-add-form__image"
                      src={institutionData?.logo_path}
                      alt="Logo"
                    />
                  ) : (
                    <div className="align-items-center-grid institution__icon-container">
                      <BankOutlined style={{ fontSize: '100px' }} />
                    </div>
                  )}
                </Col>
                <Col span={17}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        data-testid="crm-insitution-name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            max: 250,
                          },
                        ]}
                        label={LABEL.NAME}
                      >
                        <Input className="note-title-input" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={GUTTER}>
                    <Col span={12}>
                      <div id="institution_service" className="relative">
                        <Form.Item
                          data-testid="crm-institution-service"
                          name="serviceId"
                          rules={[{ required: true }]}
                          label={LABEL.SERVICE}
                        >
                          <Select
                            onChange={handleServiceChange}
                            getPopupContainer={(): IGetPopupContainer =>
                              document.getElementById('institution_service')
                            }
                          >
                            {serviceLoading ? (
                              <Option value={''} disabled>
                                <Spin
                                  spinning
                                  size="small"
                                  className="full-width"
                                />
                              </Option>
                            ) : (
                              servicesData?.map((item, i: number) => (
                                <Option key={i} value={item?.id}>
                                  {item.name}
                                </Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div id="institution_country" className="relative">
                        <Form.Item
                          data-testid="crm-institution-country"
                          name="country"
                          rules={[{ required: true }]}
                          label={LABEL.COUNTRY}
                        >
                          <SearchableSelect
                            onChange={(name) => handleCountryChange(name)}
                            getPopupContainer={(): IGetPopupContainer =>
                              document.getElementById('institution_country')
                            }
                          >
                            {countryList?.map((item, i: number) => (
                              <Option key={i} value={item}>
                                {item}
                              </Option>
                            ))}
                          </SearchableSelect>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={GUTTER}>
                    <Col span={12}>
                      <div id="institution_workflow" className="relative">
                        <Form.Item
                          data-testid="crm-institution-workflow"
                          name="workflows"
                          rules={[{ required: true }]}
                          label={LABEL.WORKFLOW}
                        >
                          <Select
                            showArrow={true}
                            mode="multiple"
                            getPopupContainer={(): IGetPopupContainer =>
                              document.getElementById('institution_workflow')
                            }
                          >
                            {workflowLoading ? (
                              <Option value={''} disabled>
                                <Spin
                                  spinning
                                  size="small"
                                  className="full-width"
                                />
                              </Option>
                            ) : (
                              serviceWorkflow?.map((item: any, i: number) => {
                                return (
                                  <Option key={i} value={item?.id || null}>
                                    {item?.workflowType?.name}
                                  </Option>
                                );
                              })
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div id="institution_type" className="relative">
                        <Form.Item
                          data-testid="crm-institution-type"
                          name="institutionTypeId"
                          rules={[{ required: true }]}
                          label={LABEL.TYPE}
                        >
                          <Select
                            getPopupContainer={(): IGetPopupContainer =>
                              document.getElementById('institution_type')
                            }
                          >
                            {typeLoading ? (
                              <Option value={''} disabled>
                                <Spin
                                  spinning
                                  size="small"
                                  className="full-width"
                                />
                              </Option>
                            ) : (
                              instituteType?.map((item: any) => (
                                <Option key={item.id} value={item.id}>
                                  {item.name}
                                </Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={GUTTER}>
                    <Col span={12}>
                      <div id="institution_currency" className="relative">
                        <Form.Item
                          data-testid="crm-institution-currency"
                          name="currency"
                          rules={[{ required: true }]}
                          label={LABEL.CURRENCY}
                        >
                          <Select
                            showSearch
                            getPopupContainer={(): IGetPopupContainer =>
                              document.getElementById('institution_currency')
                            }
                            optionFilterProp='label'
                          >
                            {sortedCurrency.map((item, i: number) => (
                              <Option key={i} label={item.name} value={item.name}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        data-testid="crm-institution-code"
                        name="identificationSystemCode"
                        rules={[{ max: 15 }]}
                        label={TEXT.INSTITUTION_CODE}
                      >
                        <Input className="note-title-input" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <Divider />
          <Collapse
            defaultActiveKey={['1']}
            bordered={false}
            ghost={true}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel header={<span>{TEXT.BRANCHES}</span>} key="1">
              <Form.List
                initialValue={[{ isMainBranch: true }]}
                name="branches"
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <div key={key}>
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              data-testid="crm-institution-branch-name"
                              {...restField}
                              label={LABEL.NAME}
                              name={[name, 'name']}
                              rules={[
                                {
                                  required: true,
                                  max: 250,
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              name={[name, 'isMainBranch']}
                              className="institution-add-form__checkbox"
                            >
                              <div>
                                <Radio
                                  id={`${key}`}
                                  className="radio_dyno"
                                  name="mainBranchInput"
                                  checked={isMainBranch(name)}
                                  onChange={() => handleBranchChange(name)}
                                />
                                <label
                                  className="margin-left-1"
                                  htmlFor={`${key}`}
                                >
                                  {MAIN_BRANCH}
                                </label>
                              </div>
                            </Form.Item>
                          </Col>
                          {!isMainBranch(name) ? (
                            <Col span={4}>
                              <Button
                                onClick={() => remove(name)}
                                type="link"
                                danger
                                icon={<DeleteOutlined />}
                              >
                                {DELETE_BTN}
                              </Button>
                            </Col>
                          ) : null}
                        </Row>
                        <Collapse
                          defaultActiveKey={['1']}
                          bordered={false}
                          ghost={true}
                          expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                          )}
                        >
                          <Panel header={<span>{TEXT.ADDRESS}</span>} key="1">
                            <Form.Item>
                              <Input.Group>
                                <Row gutter={GUTTER}>
                                  <Col span={12}>
                                    <Form.Item
                                      data-testid="crm-institution-branch-city"
                                      {...restField}
                                      label={LABEL.CITY}
                                      name={[name, 'address', 'city']}
                                      fieldKey={[fieldKey, 'city']}
                                      rules={[
                                        {
                                          required: true,
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      data-testid="crm-institution-branch-state"
                                      {...restField}
                                      label={LABEL.STATE}
                                      name={[name, 'address', 'state']}
                                      fieldKey={[fieldKey, 'state']}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={GUTTER}>
                                  <Col span={12}>
                                    <Form.Item
                                      data-testid="crm-institution-branch-postalcode"
                                      {...restField}
                                      label={LABEL.POSTAL_CODE}
                                      name={[name, 'address', 'postalCode']}
                                      fieldKey={[fieldKey, 'postalCode']}
                                    >
                                      <Input type={'number'} />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      data-testid="crm-institution-branch-street"
                                      {...restField}
                                      label={LABEL.STREET}
                                      name={[name, 'address', 'street']}
                                      fieldKey={[fieldKey, 'street']}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Input.Group>
                            </Form.Item>
                          </Panel>
                        </Collapse>
                        <Collapse
                          defaultActiveKey={['1']}
                          bordered={false}
                          ghost={true}
                          expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                          )}
                        >
                          <Panel
                            header={<span>{TEXT.CONTACT_DETAILS}</span>}
                            key="1"
                          >
                            <Row gutter={GUTTER}>
                              <Col span={12}>
                                <Form.Item
                                  data-testid="crm-institution-branch-number"
                                  {...restField}
                                  label={LABEL.PHONE_NUMBER}
                                  name={[name, 'phoneNumber']}
                                  normalize={(value) => value.replace(/ /g, '')}
                                  fieldKey={[fieldKey, 'phoneNumber']}
                                  rules={[
                                    {
                                      pattern: new RegExp(regex.ONLY_NUMBER),
                                      message: PHONE_NUMBER_INVALID,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  data-testid="crm-institution-branch-email"
                                  {...restField}
                                  label={LABEL.EMAIL}
                                  name={[name, 'email']}
                                  fieldKey={[fieldKey, 'email']}
                                  normalize={(value) => value.replace(/ /g, '')}
                                  rules={[
                                    {
                                      pattern: new RegExp(regex.EMAIL),
                                      message: AUTH_MESSAGE.INVALID_EMAIL,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                              <Col span={12}>
                                <Form.Item
                                  data-testid="crm-institution-branch-fax"
                                  {...restField}
                                  label={LABEL.FAX}
                                  name={[name, 'fax']}
                                  fieldKey={[fieldKey, 'fax']}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  data-testid="crm-institution-branch-website"
                                  {...restField}
                                  label={LABEL.WEBSITE}
                                  name={[name, 'website']}
                                  fieldKey={[fieldKey, 'website']}
                                  rules={[
                                    {
                                      required: false,
                                      pattern: new RegExp(regex.WEBSITE),
                                      message: AUTH_MESSAGE.INVALID_WEB_URL,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Panel>
                        </Collapse>
                        <Divider className="institution-add-form__divider" />
                      </div>
                    ))}
                    <Row justify="end">
                      <Form.Item>
                        <Button
                          type="link"
                          onClick={() =>
                            addNewBranch(() => {
                              add();
                            })
                          }
                          icon={<PlusOutlined />}
                        >
                          {TEXT.ADD_ANOTHER_BRANCH}
                        </Button>
                      </Form.Item>
                    </Row>
                  </>
                )}
              </Form.List>
            </Panel>
          </Collapse>
        </Form>
      </Spin>
    </>
  );
};

export default InstitutionForm;
