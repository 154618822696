import React from 'react';
import { Button, Space, Typography } from 'antd';
import { DrawerElem } from '@shared-components/elements';
import {
  CANCEL_BTN,
  DRAWER_WIDTH,
  SAVE_BTN,
  TEXT,
  UPDATE_BTN,
} from '@moxie/constants';
import InstitutionForm from './institution-form';
import { IInstitutionDrawer } from '@shared-components/models';

const InstitutionDrawer = ({
  handleClose,
  isOpen,
  handleSubmit,
  form,
  disabled,
  setDisabled,
  isEdit,
  setNameValidationStatus,
  nameValidationStatus,
  submitted,
  setSubmitted,
}: Omit<IInstitutionDrawer, 'triggerRefresh'>) => {
  const { Title } = Typography;

  const handleFormSubmit = async () => {
    setSubmitted(true);
    setDisabled(true);
    if (!form.getFieldValue('branches')) {
      setNameValidationStatus('error');
      Promise.resolve(true);
    }
    try {
      await form.validateFields();
      form.submit();
    } catch {
      setSubmitted(false);
    }
  };

  return (
    <DrawerElem
      title={
        isEdit ? (
          <Title level={4}>{TEXT.EDIT_INSTITUTE}</Title>
        ) : (
          <Title level={4}>{TEXT.ADD_INSTITUTE}</Title>
        )
      }
      width={DRAWER_WIDTH}
      onClose={handleClose}
      visible={isOpen}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button
              onClick={handleClose}
              className="lead-margin-right"
              disabled={disabled}
            >
              {CANCEL_BTN}
            </Button>
            <Button
              onClick={handleFormSubmit}
              type="primary"
              disabled={disabled}
            >
              {isEdit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <InstitutionForm
        form={form && form}
        onSubmit={handleSubmit}
        setDisabled={setDisabled}
        disabled={disabled}
        nameValidationStatus={nameValidationStatus}
        setNameValidationStatus={setNameValidationStatus}
        submitted={submitted}
        setSubmitted={setSubmitted}
        isEdit={isEdit}
      />
    </DrawerElem>
  );
};

export default InstitutionDrawer;
