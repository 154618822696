import Form from "antd/es/form";
import Modal from "antd/es/modal/Modal";
import React, { useContext, useEffect, useState } from "react";
import { MentionEditor, useTipTapEditor } from "@crm/src/shared/editor";
import Divider from "antd/es/divider";
import Button from "antd/es/button";
import { Row } from "antd";
import SaveOutlined from '@ant-design/icons/SaveOutlined';
import RetweetOutlined from '@ant-design/icons/RetweetOutlined';
import summarizeNoteInput from "../editor/summarize-note";
import aiSystemPrompt from "./system-prompt";
import { ReactComponent as AiIcon } from '@crm/src/assets/icons/ai-star-outlined.svg';
import Icon from '@ant-design/icons';
import TaskForm from "./task-form";
import { Note, TaskFormFields, TaskPayload } from "@model/crm/note.model";
import moment from "moment";
import { errorNotificationHandler } from "@moxie/shared";
import { NoteFormContext } from "./note-form-provider";

interface Props {
  visible: boolean;
  onClose: () => void;
  title: string;
  onSubmit: (description: string, assignees: string[], values?: TaskPayload) => Promise<void>;
  contactId?: string;
  note?: Note;
}

type ButtonState = 'generate-bullet-point' | 'create-action-plan' | 'save' | undefined;
const NoteModal: React.FC<Props> = ({
  visible,
  onClose,
  title,
  onSubmit,
  contactId,
  note,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<ButtonState>(undefined);
  const [key, setKey] = useState(0);
  const editor = useTipTapEditor();
  const { validateForm } = useContext(NoteFormContext);

  useEffect(() => {
    if (!note && visible) {
      if (!editor?.isActive('bulletList')) {
        editor?.chain().focus().toggleBulletList().run();
      }
      form.setFields([{
        name: 'dueDateLabel',
        value: 'tomorrow',
      },
      {
        name: 'dueAt',
        value: moment().add('days', 1),
      }
      ]);
      setKey(k => k + 1);
    }

    if (!!note && visible) {
      const task = note.task;
      if (task) {
        const dueDate = moment(task.dueAt);
        form.setFieldsValue({
          dueAt: dueDate,
          dueDateLabel: 'custom date',
          type: task.type,
          createTask: true,
        });
        editor?.commands.setContent(note.description);
        setKey(k => k + 1);

      }
    }

  }, [visible, note, editor, form])

  const onFinish = async (values: TaskFormFields) => {
    if (editor) {
      await validateForm(editor, form, values, async (content, assignees) => {

        setLoading('save');
        await onSubmit(content, assignees, values.createTask ? {
          contactId: values?.contactId,
          dueAt: values?.dueAt?.toISOString(),
          type: values?.type,
        } : undefined);

        form.resetFields();
        editor.commands.clearNodes();
        editor.destroy();

        setLoading(undefined);
      })
    }
  }

  const summarize = async (prompt: string, type: ButtonState) => {
    try {
      setLoading(type);
      await summarizeNoteInput(editor, prompt)
      setLoading(undefined);

    } catch (error) {
      errorNotificationHandler('Something went wrong!');
      setLoading(undefined);

    }
  }

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={() => {
        editor?.commands.clearContent();
        form.resetFields();
        editor?.destroy();
        onClose()
      }}
      destroyOnClose
      closable={loading === undefined}
      className="note-modal"
      style={{
        bottom: 20,
      }}
      footer={
        <Row>
          <Button
            disabled={loading !== undefined}
            htmlType="button"
            icon={<RetweetOutlined />}
            onClick={() => summarize(aiSystemPrompt.actionPlans, 'create-action-plan')} loading={loading === 'create-action-plan'}>
            Create Action Plan
            <Icon>
              <AiIcon />
            </Icon>
          </Button>
          <div style={{ 'flexBasis': '100%' }} />
          <Button
            type="primary"
            disabled={loading !== undefined}
            loading={loading === 'save'}
            form="note-modal"
            htmlType="submit"
            icon={<SaveOutlined />}
          >
            Save
          </Button>

        </Row>
      }>
      <Form form={form} id="note-modal" onFinish={onFinish}>
        <Form.Item name="description">
          <MentionEditor editor={editor} />
        </Form.Item>
        <Divider />
        <TaskForm form={form} key={key} task={note?.task} />
        <Form.Item hidden name="contactId" initialValue={contactId} />
      </Form >
    </Modal >
  )
}

export default NoteModal;
