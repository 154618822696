import React from 'react';
import { IInstitutionBranch } from '@shared-components/models';
import { Tag, Tooltip } from 'antd';
import Typography from 'antd/es/typography';

const InstitutionBranchTagGroup = ({
  data,
  numberOfBranch,
}: {
  data: IInstitutionBranch[];
  numberOfBranch: number;
}) => {
  const text = (
    <div>
      {data &&
        data
          .slice(numberOfBranch, data.length)
          .map((branch: IInstitutionBranch) => {
            return (
              <Tag
                className="margin-bottom-1 initial_capital"
                key={branch?.id}
                color="default"
              >
                <Typography.Text className='institution-list__branches__ellipsis-text' ellipsis={{ tooltip: true }}>{branch?.name}</Typography.Text>
              </Tag>
            );
          })}
    </div>
  );
  return (
    <>
      {data &&
        data.slice(0, numberOfBranch).map((branch: IInstitutionBranch) => {
          return (
            <Tag key={branch?.id} color="default" className="initial_capital">
              <Typography.Text className='institution-list__branches__ellipsis-text' ellipsis={{ tooltip: true }}>{branch?.name}</Typography.Text>
            </Tag>
          );
        })}

      {data && data.length > numberOfBranch && (
        <Tooltip placement="topLeft" color="white" title={text}>
          <Tag color="default">+{data?.length - numberOfBranch}</Tag>
        </Tooltip>
      )}
    </>
  );
};

InstitutionBranchTagGroup.defaultProps = {
  numberOfBranch: 2,
};

export { InstitutionBranchTagGroup };
