import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Button, Result } from 'antd';
import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { URL_DASHBOARD } from '@moxie/constants';

export const CustomErrorBoundary = ({ children }: { children: ReactNode }) => {
  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>;
};

function Fallback({
  error,
  resetErrorBoundary,
}: FallbackProps) {

  const history = useHistory();
  const handleRedirection = () => {
    history.push(URL_DASHBOARD);
    resetErrorBoundary();
  }

  if (error.name === 'ChunkLoadError') {
    return (
      <Result
        status="500"
        title="500"
        subTitle="This application has been updated, please refresh your browser to see the latest content."
        extra={
          <Button onClick={() => window.location.reload()} type="primary">
            Reload
          </Button>
        }
      />
    );
  }

  return (
    <Result
      status="500"
      title="404"
      subTitle={
        <p>
          Something went wrong.
          <pre style={{ color: 'red' }}>{error.message}</pre>
        </p>
      }
      extra={
        <>
          <Button onClick={() => resetErrorBoundary()} type="primary">
            Reset
          </Button>
          <Button onClick={handleRedirection} type="primary">
            Back to Home
          </Button>
        </>
      }
    />
  );
}
