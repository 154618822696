import { Task, TaskFormFields, TaskType } from "@model/crm/note.model";
import { classNames } from "@moxie/constants";
import { Popover } from "antd"
import Checkbox from "antd/es/checkbox"
import DatePicker from "antd/es/date-picker";
import Form, { FormInstance } from "antd/es/form"
import moment from "moment";
import { useEffect, useRef, useState } from "react"
import DownOutlined from '@ant-design/icons/DownOutlined';


const taskType: TaskType[] = [
  'to do',
  'email',
  'call',
  'follow up'
]

const taskReminder = [
  'tomorrow',
  'next week',
  'two weeks from now'
]
interface Props {
  form: FormInstance;
  task?: Task;

}
const TaskForm: React.FC<Props> = ({ form, task }) => {

  const isCreated = Boolean(task?.id);

  return (
    <>
      <Form.Item name="createTask" valuePropName="checked" style={{ marginBottom: 0, display: 'inline-flex' }}>
        <Checkbox className="task-checkbox" name="createTask" disabled={isCreated} />
      </Form.Item>
      <div className="task-form-label">
        {isCreated ? 'Update' : 'Create a'}
        <TaskTypePopover form={form} />
        task to follow up
        <DueDatePopover form={form} />
      </div>
    </>
  )
}

interface TaskTypePopoverProps {
  form: FormInstance<TaskFormFields>;
}
const TaskTypePopover: React.FC<TaskTypePopoverProps> = ({ form }) => {
  const [type, setType] = useState<TaskType>('to do');
  const typeRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setType(form.getFieldValue('type'));
  }, [form]);

  const onSelect = (selectedType: TaskType) => {
    setType(selectedType);
    form.setFieldsValue({
      type: selectedType,
    });
    typeRef.current?.click();
  }
  return (
    <Popover
      className="task-popover"
      trigger={['click']}
      placement="bottom"
      overlayClassName="task-popover-content"
      content={
        <div className="task-options">
          {taskType.map(task => (
            <div
              key={task}
              className={classNames(task === type ? 'active' : null)}
              onClick={() => onSelect(task)}
            >
              {task}
            </div>
          ))}
        </div>
      }
    >
      <Form.Item name={['type']} hidden initialValue={'to do'} />
      <span className="task-type" ref={typeRef}>{type}<DownOutlined /></span>
    </Popover>
  )
}

interface DueDatePopoverProps {
  onChange?: (value: string) => void;
  form: FormInstance
}

const getDate = (value: string) => {
  switch (value) {
    case 'tomorrow':
      return moment().add('days', 1)
    case 'next week':
      return moment().add('weeks', 1)
    case 'two weeks from now':
      return moment().add('weeks', 2);
    default:
      return;
  }
}
const DueDatePopover: React.FC<DueDatePopoverProps> = ({ form }) => {
  const [dueDate, setDueDate] = useState('tomorrow');
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setDueDate(form.getFieldValue('dueDateLabel'));
  }, [form]);

  const onSelect = (value: string) => {
    setDueDate(value);
    if (taskReminder.includes(value)) {
      form.setFields([{
        name: 'dueAt',
        value: getDate(value),
      },
      {
        name: 'dueDateLabel',
        value: value,
      }
      ])
    }
    ref.current?.click();
  }

  if (taskReminder.includes(dueDate)) {
    return (
      <>
        <Popover
          trigger={['click']}
          placement="bottom"
          overlayClassName="task-popover-content"
          content={
            <div className="task-options">
              {
                taskReminder.includes(dueDate) && (
                  <>
                    {taskReminder.map(label => (
                      <div key={label} onClick={() => onSelect(label)}>
                        {label}
                      </div>
                    ))}
                    <div onClick={() => onSelect('custom date')}>Custom date</div>
                  </>
                )
              }
            </div>
          }>
          <span className="task-type" ref={ref}>{dueDate}<DownOutlined /></span>
          <Form.Item hidden name="dueDateLabel" initialValue={'tomorrow'} />
          <Form.Item hidden name="dueAt" />
        </Popover >
      </>
    )
  }

  return (
    <>
      <Form.Item name="dueAt" style={{ 'display': 'inline-block', paddingLeft: '0.5rem', width: '130px', marginBottom: '0' }} required>
        <DatePicker format="DD/MM/YYYY" />
      </Form.Item>
    </>
  )
}

export default TaskForm;
