import {
  SLUG_CLIENTS,
  SLUG_INTERESTED_SERVICES, SLUG_KPI,
  SLUG_PROFILE_ACTIVITIES,
  SLUG_PROFILE_APPLICATIONS,
  // SLUG_PROFILE_APPOINTMENTS,
  SLUG_PROFILE_DOCUMENTS, SLUG_PROFILE_SECTION
} from './slug';

export const CLIENT_STATUS = ['Lead', 'Client', 'Prospect'];

export const INTAKE_MONTHS = [
  { id: 1, intake: 'January' },
  { id: 2, intake: 'February' },
  { id: 3, intake: 'March' },
  { id: 4, intake: 'April' },
  { id: 5, intake: 'May' },
  { id: 6, intake: 'June' },
  { id: 7, intake: 'July' },
  { id: 8, intake: 'August' },
  { id: 9, intake: 'September' },
  { id: 10, intake: 'October' },
  { id: 11, intake: 'November' },
  { id: 12, intake: 'December' },
];

const MANDATORY_DATA = [
  { id: 1, name: 'First Name' },
  { id: 2, name: 'Last Name' },
  { id: 3, name: 'Phone' },
  { id: 4, name: 'Email' },
];

const OPTIONAL_DATA = [
  { id: 1, name: 'Street' },
  { id: 2, name: 'City' },
  { id: 3, name: 'State' },
  { id: 4, name: 'Phone Number Country Code' },
  { id: 5, name: 'Date Of Birth' },
  { id: 6, name: 'Postal Code' },
  { id: 7, name: 'Country' },
  { id: 8, name: 'Country of Passport' },
  { id: 9, name: 'Visa Expiry Date' },
];

const TITLE_NO = ['1', '2', '3'];

export { MANDATORY_DATA, OPTIONAL_DATA, TITLE_NO };

export const DIRECTOR = 'director';
export const BUSINESS_OWNER = 'business_owner';
export const PARTNER = 'partner';
export const CEO = 'CEO';
export const OFFICE_MANAGER = 'office_manager';
export const HEAD_COUNSELOR = 'head_counselor';
export const MARKETING_AND_OTHERS = 'marketing_and_others';

export const INSTALLMENT_TYPE = [
  { name: 'Full Fee', value: 'full_fee' },
  { name: 'Semester Fee', value: 'semester_fee' },
  { name: 'Trimester Fee', value: 'trimester_fee' },
  { name: 'Per Credit Fee', value: 'pre_credit_fee' },
];

export const FEE_TYPE_LIST = [
  { name: 'Tuition Fee', value: 'tuition_fee' },
  { name: 'Other Fees', value: 'other_fees' },
];

export const COUNTRY_CURRENCY = [
  { id: 1, name: 'AUD' },
  { id: 2, name: 'GBP' },
  { id: 3, name: 'CAD' },
  { id: 4, name: 'USD' },
  { id: 5, name: 'NZD' },
  { id: 6, name: 'CZK' },
  { id: 7, name: 'VND' },
  { id: 8, name: 'MYR' },
  { id: 9, name: 'THB' },
  { id: 10, name: 'SGD' },
  { id: 11, name: 'PLN' },
  { id: 12, name: 'CHF' },
  { id: 13, name: 'NPR' },
  { id: 14, name: 'INR' },
  { id: 15, name: 'EUR' },
  { id: 16, name: 'AED' },
  { id: 17, name: 'LKR' },
  { id: 18, name: 'BDT' },
  { id: 19, name: 'IDR' },
  { id: 20, name: 'MMK' },
  { id: 21, name: 'BTN' },
  { id: 22, name: 'NGN' },
  { id: 23, name: 'KHR' },
  { id: 24, name: 'PHP' },
  { id: 25, name: 'KES' },
  { id: 26, name: 'LAK' }
];
export const LABEL_OPTION = ['Text', 'Numeric', 'Option', 'Yes/No', 'Date'];
export const BOOLEAN_VALUE = ['Yes', 'No'];

export const ROLES = [
  { value: DIRECTOR, name: 'Director' },
  { name: 'Business Owner', value: BUSINESS_OWNER },
  { name: 'Partner', value: PARTNER },
  { name: 'CEO', value: CEO },
  { name: 'Office Manager', value: OFFICE_MANAGER },
  { name: 'Head Counselor', value: HEAD_COUNSELOR },
  { name: 'Marketing and Others', value: MARKETING_AND_OTHERS },
];

export const CATEGORIES = [
  { name: 'Reminder', value: 'reminder' },
  { name: 'Call', value: 'call' },
  { name: 'Follow Up', value: 'follow-up' },
  { name: 'Email', value: 'email' },
  { name: 'Meeting', value: 'meeting' },
  { name: 'Support', value: 'support' },
  { name: 'Others', value: 'others' },
];

export const INTAKES = [
  { name: 'January', value: 'january' },
  { name: 'February', value: 'february' },
  { name: 'March', value: 'march' },
  { name: 'April', value: 'april' },
  { name: 'May', value: 'may' },
  { name: 'June', value: 'june' },
  { name: 'July', value: 'july' },
  { name: 'August', value: 'august' },
  { name: 'September', value: 'september' },
  { name: 'October', value: 'october' },
  { name: 'November', value: 'november' },
  { name: 'December', value: 'december' },
];

export const PRIORITIES = [
  { name: 'Low', value: 'low' },
  { name: 'Normal', value: 'normal' },
  { name: 'High', value: 'high' },
  { name: 'Urgent', value: 'urgent' },
];

export const RELATED_TO_LIST = [
  { name: 'Contact', value: 'contact' },
  { name: 'Internal', value: 'internal' },
];

export const FEATURES = [
  { name: 'CRM', value: 'crm' },
  { name: 'Agent Marketplace', value: 'marketing' },
];

export const NUMBER_OF_EMPLOYEES = [
  { name: '1-5', key: '1' },
  { name: '5-10', key: '2' },
  { name: '10+', key: '3' },
];

export const NUMBER_OF_DESTINATION_COUNTRIES = [
  { name: '1-5', value: '1' },
  { name: '5-10', value: '2' },
  { name: '10+', value: '3' },
];

export const NUMBER_OF_ESTIMATES = [
  { name: '1-5', key: '1' },
  { name: '5-10', key: '2' },
  { name: '10+', key: '3' },
];

export const PROFILE_HEADERS = [
  {
    id: 0,
    name: 'Activities',
    slug: 'activities',
    url: `${SLUG_PROFILE_ACTIVITIES}`,
  },
  {
    id: 1,
    name: 'Profile',
    slug: SLUG_PROFILE_SECTION,
    url: SLUG_PROFILE_SECTION,
  },
  {
    id: 2,
    name: 'Interested Services',
    slug: SLUG_INTERESTED_SERVICES,
    url: SLUG_INTERESTED_SERVICES,
  },
  {
    id: 3,
    name: 'Applications',
    slug: 'applications',
    url: `${SLUG_PROFILE_APPLICATIONS}`,
  },
  {
    id: 4,
    name: 'Documents',
    slug: 'documents',
    url: `${SLUG_PROFILE_DOCUMENTS}`,
  },
  // {
  //   id: 5,
  //   name: 'Appointments',
  //   slug: 'appointments',
  //   url: `${SLUG_PROFILE_APPOINTMENTS}`,
  // },
  {
    id: 6,
    name: 'Notes',
    slug: 'notes',
    url: 'notes',
  },
];

export const PRODUCT_PROFILE_HEADERS = [
  // {
  //   id: 0,
  //   name: 'Applications',
  //   slug: 'applications',
  //   url: `${SLUG_PROFILE_APPLICATIONS}`,
  // },
  {
    id: 1,
    name: 'Documents',
    slug: 'documents',
    url: `${SLUG_PROFILE_DOCUMENTS}`,
  },
  {
    id: 2,
    name: 'Fees',
    slug: 'fees',
    url: 'fees',
  },
  {
    id: 3,
    name: 'Requirements',
    slug: 'requirements',
    url: 'requirements',
  },
  {
    id: 4,
    name: 'Other Information',
    slug: 'other-information',
    url: 'other-information',
  },
  {
    id: 5,
    name: 'Promotions',
    slug: 'promotions',
    url: 'promotions',
  },
  {
    id: 6,
    name: 'Descriptions',
    slug: 'description',
    url: 'description',
  },
];

export const MY_PROFILE_HEADERS = [
  { id: 0, name: 'Clients', slug: SLUG_CLIENTS, url: `${SLUG_CLIENTS}` },
  { id: 1, name: 'Kpi', slug: SLUG_KPI, url: `${SLUG_KPI}` },
];

export const DOCUMENT_TYPES = [
  {
    key: 'passport',
    name: 'Passport',
    value: 'Personal<Passport',
  },
  { key: 'citizenship', name: 'Citizenship', value: 'Personal<Citizenship' },

  { key: 'cv', name: 'CV', value: 'Personal<CV' },
  {
    key: 'relationship_certificate',
    name: 'Relationship Certificate',
    value: 'Personal<Relationship Certificate',
  },
  {
    key: 'no_objection_certificate',
    name: 'No Objection Certificate',
    value: 'Financial<No Objection Certificate',
  },
  {
    key: 'sponsorship_letter',
    name: 'Sponsorship Letter',
    value: 'Financial<Sponsorship Letter',
  },
  {
    key: 'bank_statement',
    name: 'Bank Statement',
    value: 'Financial<Bank Statement',
  },
  {
    key: 'salary_statement',
    name: 'Salary Statement',
    value: 'Financial<Salary Statement',
  },
  {
    key: 'property_valuation',
    name: 'Property Valuation',
    value: 'Financial<Property Valuation',
  },
  {
    key: 'rental_agreement',
    name: 'Rental Agreement',
    value: 'Financial<Rental Agreement',
  },
  {
    key: 'income_statement',
    name: 'Income Statement',
    value: 'Financial<Income Statement',
  },
  {
    key: 'proof_of_payment',
    name: 'Proof of Payment',
    value: 'Financial<Proof of payment',
  },
  { key: 'transcript', name: 'Transcript', value: 'Academic<Transcript' },
  {
    key: 'academic_certificate',
    name: 'Academic Certificates',
    value: 'Academic<Academic Certificates',
  },
  {
    key: 'letter_of_recommendation',
    name: 'Letter of Recommendation',
    value: 'Academic<Letter of Recommendation',
  },
  {
    key: 'statement_of_purpose',
    name: 'Statement of Purpose',
    value: 'Academic<Statement of Purpose',
  },
  {
    key: 'experience_letter',
    name: 'Experience Letter',
    value: 'Professional<Experience Letter',
  },
  { key: 'others', name: 'Others', value: 'Others<Others' },
];

export const INDUSTRY = [
  // The record from id 1 to 4 was already added so didn't modified the key and value, id is added later for uniqueness when mapping
  { id: 1, name: 'IT & Telecommunication', value: 'IT & Telecommunication' },
  { id: 2, name: 'Construction', value: 'construction' },
  { id: 3, name: 'Forestry', value: 'forestry' },
  { id: 4, name: 'Agriculture', value: 'agriculture' },
  { id: 5, name: 'Accounting', value: 'accounting' },
  { id: 6, name: 'Advertising Services', value: 'advertising_services' },
  { id: 7, name: 'Aerospace & Aviation', value: 'aerospace_aviation' },
  { id: 8, name: 'Artificial Intelligence (AI)', value: 'artificial_intelligence' },
  { id: 9, name: 'Automotive', value: 'automotive' },
  { id: 10, name: 'Banking & Financial Services', value: 'banking_financial_services' },
  { id: 11, name: 'Biotechnology', value: 'biotechnology' },
  { id: 12, name: 'Chemicals', value: 'chemicals' },
  { id: 13, name: 'Consumer Goods', value: 'consumer_goods' },
  { id: 14, name: 'Consumer Services', value: 'consumer_services' },
  { id: 15, name: 'Cybersecurity', value: 'cybersecurity' },
  { id: 16, name: 'Data Science', value: 'data_science' },
  { id: 17, name: 'Defense & Space', value: 'defense_space' },
  { id: 18, name: 'Digital Marketing', value: 'digital_marketing' },
  { id: 19, name: 'E-commerce', value: 'e_commerce' },
  { id: 20, name: 'Education & Training', value: 'education_training' },
  { id: 21, name: 'Electronics & Semiconductors', value: 'electronics_semiconductors' },
  { id: 22, name: 'Energy (Traditional & Renewable)', value: 'energy_traditional_renewable' },
  { id: 23, name: 'Entertainment & Media', value: 'entertainment_media' },
  { id: 24, name: 'Environmental Services', value: 'environmental_services' },
  { id: 25, name: 'Event Management', value: 'event_management' },
  { id: 26, name: 'Financial Services', value: 'financial_services' },
  { id: 27, name: 'Fintech', value: 'fintech' },
  { id: 28, name: 'Fishing & Aquaculture', value: 'fishing_aquaculture' },
  { id: 29, name: 'Food & Beverage', value: 'food_beverage' },
  { id: 30, name: 'Government & Public Administration', value: 'government_public_administration' },
  { id: 31, name: 'Handicrafts & Cultural Industries', value: 'handicrafts_cultural_industries' },
  { id: 32, name: 'Healthcare', value: 'healthcare' },
  { id: 33, name: 'Hospitality & Tourism', value: 'hospitality_tourism' },
  { id: 34, name: 'Human Resources & Staffing', value: 'human_resources_staffing' },
  { id: 35, name: 'Informal & Microenterprise Sector', value: 'informal_microenterprise_sector' },
  { id: 36, name: 'Insurance', value: 'insurance' },
  { id: 37, name: 'Internet Service Providers', value: 'internet_service_providers' },
  { id: 38, name: 'Investment Management', value: 'investment_management' },
  { id: 39, name: 'Legal Services & Law Firms', value: 'legal_services_law_firms' },
  { id: 40, name: 'Logistics & Transportation', value: 'logistics_transportation' },
  { id: 41, name: 'Manufacturing', value: 'manufacturing' },
  { id: 42, name: 'Marketing', value: 'marketing' },
  { id: 43, name: 'Mental Health Services', value: 'mental_health_services' },
  { id: 44, name: 'Mining & Extraction', value: 'mining_extraction' },
  { id: 45, name: 'Nanotechnology', value: 'nanotechnology' },
  { id: 46, name: 'Non-Profit Organizations', value: 'non_profit_organizations' },
  { id: 47, name: 'Oil & Gas', value: 'oil_gas' },
  { id: 48, name: 'Pharmaceuticals', value: 'pharmaceuticals' },
  { id: 49, name: 'Private Equity & Venture Capital', value: 'private_equity_venture_capital' },
  { id: 50, name: 'Professional Services (Consulting, Auditing, Advisory)', value: 'professional_services_consulting_auditing_advisory' },
  { id: 51, name: 'Real Estate', value: 'real_estate' },
  { id: 52, name: 'Retail', value: 'retail' },
  { id: 53, name: 'Rural Development', value: 'rural_development' },
  { id: 54, name: 'Shipping & Freight', value: 'shipping_freight' },
  { id: 55, name: 'Software Development', value: 'software_development' },
  { id: 56, name: 'Sports & Recreation', value: 'sports_recreation' },
  { id: 57, name: 'Supply Chain Management', value: 'supply_chain_management' },
  { id: 58, name: 'Textiles & Apparel Manufacturing', value: 'textiles_apparel_manufacturing' },
  { id: 59, name: 'Tourism & Hospitality', value: 'tourism_hospitality' },
  { id: 60, name: 'Urban Development', value: 'urban_development' },
  { id: 61, name: 'Waste Management & Recycling', value: 'waste_management_recycling' },
  { id: 62, name: 'Water & Sanitation Services', value: 'water_sanitation_services' },
  { id: 63, name: 'Wholesale & Distribution', value: 'wholesale_distribution' }
];

export const EMPLOYMENT_TYPE = [
  { name: 'Part Time', value: 'part_time' },
  { name: 'Full Time', value: 'full_time' },
  { name: 'Self Employed', value: 'self_employed' },
  { name: 'Freelance', value: 'freelance' },
  { name: 'Contract', value: 'contract' },
  { name: 'Internship', value: 'internship' },
  { name: 'Apprenticeship', value: 'apprenticeship' },
];

export const INTERESTED_SERVICE_DURATION = [
  { name: 'Less than 6 Month', value: 'less_than_six_month' },
  { name: 'Less than 1 Year', value: 'less_than_one_year' },
  { name: 'More than 1 Year', value: 'more_than_one_year' },
];

export const WORKFLOW_STAGE_ACTORS = [
  { name: 'All', value: 'All' },
  { name: 'Client', value: 'Client' },
  { name: 'Admission', value: 'Admission' },
];

export const FEE_TYPE = ['Annually', 'Monthly', 'Quarterly'];
export const LANGUAGE_LIST = [{ language_name: 'ENG', language_value: 'eng' }];

export const CATEGORY_ITEMS = [
  {
    key: 'personal',
    name: 'Personal Information',
  },
  {
    key: 'address',
    name: 'Address Information',
  },
  {
    key: 'passport',
    name: 'Passport and Visa Information',
  },
  {
    key: 'emergency_contact',
    name: 'Emergency Contact Details',
  },
  {
    key: 'financial',
    name: 'Financial Details',
  },
];

export const GENDER_ITEMS = ['Male', 'Female', 'Others'];

export const CRM_INSTALLMENT_TYPE = [
  { name: 'Full Fee', value: 'full_fee' },
  { name: 'Semester Fee', value: 'semester_fee' },
  { name: 'Trimester Fee', value: 'trimester_fee' },
  { name: 'Per Credit Fee', value: 'pre_credit_fee' },
];
