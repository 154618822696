export const REFERRAL_SOURCE_OPTIONS = [
  { id: 1, name: 'Facebook', value: 'facebook' },
  { id: 2, name: 'Instagram', value: 'instagram' },
  { id: 3, name: 'TikTok', value: 'tiktok' },
  { id: 4, name: 'Advertisement', value: 'advertisement' },
  { id: 5, name: 'Website', value: 'website' },
  { id: 6, name: 'Friends', value: 'friends' },
  { id: 7, name: 'Staff Referral', value: 'staff_referral' },
  { id: 8, name: 'Other', value: 'other' }
];

export const VISA_HISTORY_LABEL = 'Please mention any significant visa history, if there is any';
