import React, { ReactNode } from "react";
import { Card, Form, Input } from "antd";

const EnquiryViewTextAreaField = ({
  editMode,
  name,
  title,
  placeholder
}: { editMode: boolean; name: string; title: ReactNode; placeholder: string; }) => {
  return (
    <Card className="margin-top-2" title={title} headStyle={{ background: "none" }}>
      <Form.Item name={name}>
        <Input.TextArea className={!editMode ? "enquiry-view-form____form-container__text-area enquiry-view-form__cursor-default" : ""} rows={3} placeholder={!editMode ? "N/A" : placeholder} readOnly={!editMode} />
      </Form.Item>
    </Card>
  )
}

export { EnquiryViewTextAreaField };
