import { getTaskCount } from "@crm/libs/services.api/lib/tasks.api"
import { useQuery } from "@tanstack/react-query"

export const useTaskCount = () => {
  const { data } = useQuery({
    queryKey: ['task-count'],
    queryFn: async () => getTaskCount(),
  })
  const { overdue_count, today_count, this_week_count, upcoming_count, completed_count } = data?.data || {}

  return { overdueCount: overdue_count, todayCount: today_count, thisWeekCount: this_week_count, upcomingCount: upcoming_count, completedCount: completed_count };
}
