import React, { useEffect, useState } from 'react';
import { PaginationMeta, CrmPaginatedResponse } from '@model/api-response.model';
import { errorNotificationHandler } from '@moxie/shared';
import { filterValidRefs } from '../helpers';
import { useDebouncedValue } from './useDebounced';

interface Props<T> {
  fetchQuery?: (params: Record<string, unknown>) => Promise<CrmPaginatedResponse<T>>;
  valueKey?: keyof T;
  labelKey?: keyof T;
  filter?: Record<string, unknown>;
  search?: string;
  setSearch?: (val: string) => void;
  value?: unknown;
  enabled?: boolean;
  hideDefaultOptionView?: boolean;
  filterValues?: string[];
  emptyOption?: React.ReactElement;
}

/***
  * @param enabled  Set this to `false` to disable automatic refetching when the query mounts.
  * */
export const useSearchBox = <T,>({
  fetchQuery,
  valueKey,
  labelKey,
  filter,
  search = "",
  setSearch,
  enabled = true,
  hideDefaultOptionView = false,
  emptyOption,
  filterValues,
  ...props
}: Props<T>) => {
  const [options, setOptions] = useState<T[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [initialLoading, setInitialLoading] = useState(true);
  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>({
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: [],
    totalItems: 0,
    totalPages: 1,
  });
  const debouncedSearch = useDebouncedValue(search, 500);
  const hasMorePage = (paginationMeta.totalPages >= page);

  const stringifiedFilter = JSON.stringify(filter)
  const fetchOptions = async (search: string, value?: unknown, page = 1, signal?: AbortSignal) => {
    const filter = stringifiedFilter ? JSON.parse(stringifiedFilter) : {}
    if (fetchQuery) {
      setLoading(true);
      try {
        let params: Record<string, unknown> = {
          ...filter,
          search,
          limit: 10,
          page,
        };

        if ((value as string | string[])?.length) {

          params = {
            ...params,
            [`filter.${valueKey as string}`]: `${Array.isArray(value) ? '$in:$not:' + value.filter(val => filterValidRefs(val)).join(',') : '$in:$not:' + value}`,
          };

          if (Array.isArray(value)) {
            const clause = value.filter(val => filterValidRefs(val)).length;
            if (!clause) {
              params.search = clause ? params.search : '';
              delete params['filter.id']
            }
          }
          else {
            const clause = filterValidRefs(value as string);
            if (!clause) {
              params.search = clause ? params.search : '';
              delete params['filter.id']
            }
          }
        }
        const {
          data: { data, meta },
        } = await fetchQuery({ ...params });
        if (page !== 1) {
          setOptions((prev) => [...prev, ...data]);
        } else {
          setOptions(data);
        }
        setPaginationMeta(meta);
        setPage(meta.currentPage);
      } catch (error) {
        if (error instanceof Error) {
          errorNotificationHandler(error.message)
        } else if (typeof error === 'string') {
          errorNotificationHandler(error)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  const optionSelectMap = React.useMemo(() => {
    if (hideDefaultOptionView) {
      return undefined;
    }
    if (labelKey && valueKey) {
      return options.map((d) => ({
        label: d[labelKey] as unknown,
        value: d[valueKey] as unknown,
      })).filter(val => !filterValues?.includes(val.value as string)) as { label: string; value: string }[];
    }
    return [];
  }, [filterValues, hideDefaultOptionView, labelKey, options, valueKey]);

  const onSearch = (value: string) => setSearch && setSearch(value);

  useEffect(() => {
    if (enabled === false) return;
    const controller = new AbortController()
    const signal = controller.signal;
    fetchOptions(debouncedSearch, props.value, undefined, signal)
      .then(() => setInitialLoading(false))
      .catch((err) => {
        setInitialLoading(false)
      });

    return () => controller.abort();
  }, [JSON.stringify(props.value), debouncedSearch, enabled]);

  const paginateOptions: React.UIEventHandler<HTMLDivElement> = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    const isScrollingNearBottom =
      Math.floor(scrollHeight - scrollTop) <= clientHeight;
    if (isScrollingNearBottom && paginationMeta.totalPages > page) {
      fetchOptions(search, props.value, page + 1);
    }
  };

  const fetchNextPage = () => {
    if (paginationMeta.totalPages > page) {
      fetchOptions(search, props.value, page + 1);
    }
  }

  return {
    options,
    paginateOptions,
    onSearch,
    isLoading,
    initialLoading,
    paginationMeta,
    fetchNextPage,
    hasMorePage,
    optionSelectMap,
  };
};
