import CloseOutlined from "@ant-design/icons/CloseOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined";
import { useAppSelector } from "@crm/core";
import { useUpdateEnquiryData } from "@crm/libs/hooks/enquiry-form/useUpdateEnquiryData";
import { useFetchInterestedServices } from "@crm/libs/hooks/interested-services/useFetchInterestedServices";
import { CustomizableUserCard } from "@crm/src/shared/user-card/customizable-user-card";
import { ICrmEnquiryData, UpdateContactEnquiryPayload } from "@model/index";
import { COUNTRIES, ENQUIRY_FORM, regex } from "@moxie/constants";
import { successNotificationHandler } from "@moxie/shared";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQueryClient } from "@tanstack/react-query";
import { Button, Divider, Row, Typography } from "antd";
import Card from "antd/es/card";
import Form, { RuleObject } from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import Col from "antd/es/grid/col";
import Space from "antd/es/space";
import { cloneDeep, merge, omit, set } from "lodash";
import isEqual from 'lodash/isEqual';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { EnquiryPersonalInfoModal } from "./enquiry-personal-info-modal";
import { EnquiryViewAddressInfo } from "./enquiry-view-address-info";
import { EnquiryViewInterestedService } from "./enquiry-view-interested-service";
import { EnquiryViewPersonalInfo } from "./enquiry-view-personal-info";
import { EnquiryViewTextAreaField } from "./enquiry-view-text-area-field";

interface EnquiryViewFormProp {
  enquiryData: ICrmEnquiryData;
  refetch: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<QueryObserverResult<any, unknown>>;
  isLoading: boolean;
  triggerRefresh?: () => void;
  canUpdate: boolean;
}

export type AvailableServices = 'Education' | 'Migration' | 'Insurance' | 'Not Sure' | "Test Prep/Booking";

const modifyEnquiryDataForDOB = (data: ICrmEnquiryData) => {
  const clonedEnquiryData = cloneDeep(data);
  const enquiryDOB = clonedEnquiryData?.personalInformation?.dateOfBirth;
  const intake = clonedEnquiryData?.interestedService?.intake;
  const studyPeriod = clonedEnquiryData?.interestedService?.educationBackgrounds?.studyPeriod;

  set(clonedEnquiryData, 'personalInformation.dateOfBirth', enquiryDOB ? moment(enquiryDOB) : undefined)
  set(clonedEnquiryData, 'interestedService.intake', intake ? moment(intake) : undefined)
  set(clonedEnquiryData, 'interestedService.educationBackgrounds.studyPeriod', studyPeriod?.length ? studyPeriod.map(date => moment(date, "DD/MM/YYYY")) : undefined)
  return clonedEnquiryData;
}

const EnquiryViewForm = ({ enquiryData, refetch, isLoading, triggerRefresh, canUpdate }: EnquiryViewFormProp) => {
  const [form] = useForm();
  const queryClient = useQueryClient();

  const [personalInfoModalForm] = useForm();
  const [editMode, setEditMode] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isPersonalInfoModalOpen, setPersonalInfoModalOpen] = useState(false);
  const { user } = useAppSelector((state) => state.auth)
  const socket = useAppSelector((state) => state.socket.wss);

  const { interestedServices } = useFetchInterestedServices();
  const { mutate } = useUpdateEnquiryData(triggerRefresh);
  const [phone, setPhone] = useState<string | undefined>();
  const [countryCode, setCountryCode] = useState<string | any>();
  const [destinationCountry, setDestinationCountry] = useState<string[] | undefined>();
  const [selectedService, setSelectedService] = useState<string | undefined>();

  const validatePhoneNumber = (_: RuleObject, value: string) => {
    const cleanedVal = value?.replace(/[()\s-]/g, '');
    if (value && !regex.LEAD_PHONE_NUMBER.test(cleanedVal)) {
      setDisabled(true)
      return Promise.reject('Please enter a valid phone number');
    }
    return Promise.resolve();
  };

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  const onPhoneNumberChange = (val: string) => {
    let phoneNumber = val
    if (val.length >= 3) {
      phoneNumber = val.replace(/^0{1,}/g, '')
    }
    setPhone(phoneNumber);
    form.setFieldsValue({ personalInformation: { phone: phoneNumber } })
  }

  const cancelEdit = () => {
    setDisabled(true);
    setEditMode(false);
    form.resetFields();
  }

  const handleServiceChange = (value: string) => {
    setSelectedService(value);
  }

  const handleDestinationCountryChange = (value: string[]) => {
    setDestinationCountry(value);
  }

  const handleFinish = (data: UpdateContactEnquiryPayload) => {
    if (data.interestedService.intake) {
      data.interestedService.intake = moment(data.interestedService.intake)?.format('MMMM YYYY');
    }
    const previousInterestedService = interestedServices.find(service => service.id === enquiryData.interestedService.interestedServiceId)
    const previousData = {
      addressInformation: {
        ...enquiryData?.addressInformation,
      },
      personalInformation: {
        ...enquiryData?.personalInformation,
        dateOfBirth: moment(enquiryData?.personalInformation?.dateOfBirth).format("YYYY-MM-DD"),
        countryCode: enquiryData.personalInformation?.phone ? countryCode : undefined,
      },

      comment: enquiryData?.comment,
      interestedService: {
        ...enquiryData?.interestedService,
        interests: enquiryData?.interestedService?.interests,
        typeOfService: previousInterestedService?.name ?? '',
        countries: enquiryData?.interestedService.destinationCountry,
        intake: enquiryData?.interestedService?.intake ?? null,
      },
      type: enquiryData?.interestedService?.type ?? null,
    };

    const interestedService = interestedServices.find(service => service.id === data.interestedService.interestedServiceId)

    const currentData = {
      addressInformation: {
        ...data?.addressInformation,
      },
      personalInformation: {
        dateOfBirth: moment(data?.personalInformation?.dateOfBirth).format("YYYY-MM-DD"),
        countryCode: data.personalInformation?.phone ? countryCode : undefined,
        email: enquiryData?.personalInformation?.email,
        firstName: enquiryData?.personalInformation?.firstName,
        lastName: enquiryData?.personalInformation?.lastName,
        phone: data.personalInformation?.phone,
        gender: data.personalInformation?.gender,
        nationality: data.personalInformation?.nationality,
        preferredContact: data.personalInformation?.preferredContact,
      },
      comment: data?.comment,
      interestedService: {
        ...data?.interestedService,
        interests: data?.interestedService?.interests,
        typeOfService: interestedService?.name ?? '',
        countries: data?.interestedService.destinationCountry,
        intake: data?.interestedService.intake ?? null,
        educationBackgrounds: {
          ...data?.interestedService.educationBackgrounds,
          studyPeriod: data?.interestedService?.educationBackgrounds?.studyPeriod ? data?.interestedService?.educationBackgrounds?.studyPeriod?.map(date => moment(date)?.format('DD/MM/YYYY')) : undefined
        }
      },
      type: data?.interestedService?.type ?? null,
    };

    let submissionData: UpdateContactEnquiryPayload = merge({}, data, {
      id: enquiryData.id,
      personalInformation: {
        ...enquiryData?.personalInformation,
        ...data?.personalInformation,
        countryCode: data.personalInformation?.phone ? countryCode : undefined,
        dateOfBirth: data?.personalInformation?.dateOfBirth
          ? moment(data?.personalInformation?.dateOfBirth).format("YYYY-MM-DD")
          : undefined,
      },
      interestedService: {
        ...data?.interestedService,
        interests: data?.interestedService?.interests,
        educationBackgrounds: {
          ...data?.interestedService.educationBackgrounds,
          studyPeriod: data?.interestedService?.educationBackgrounds?.studyPeriod ? data?.interestedService?.educationBackgrounds?.studyPeriod?.map(date => moment(date)?.format('DD/MM/YYYY')) : undefined
        }
      },
    });

    const dataMatch = isEqual(previousData, currentData);

    if (!submissionData?.personalInformation?.phone) {
      submissionData = omit(submissionData, ['personalInformation.phone']) as UpdateContactEnquiryPayload;
    }

    if (!dataMatch) {
      mutate(submissionData, {
        onSuccess: (data) => {
          refetch().then(() => {
            const modifiedEnquiryData: ICrmEnquiryData = modifyEnquiryDataForDOB(data?.data);
            form.setFieldsValue(modifiedEnquiryData);
            setDestinationCountry(data?.data?.interestedService?.destinationCountry);
          });

          socket?.emit('server::enquiry-updated', {
            userId: user?.id as string,
            companyId: user?.companyId as string,
            activitiesType: 'enquiry',
            activitiesAction: 'updated',
            enquiryId: enquiryData.id,
            data: { currentData, previousData },
          });

          if (triggerRefresh) triggerRefresh();
          queryClient.invalidateQueries({
            queryKey: ['contact-enquiry-activity'],
          });
          successNotificationHandler('Enquiry updated successfully');
        },
      })
    }
    cancelEdit();
  }

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      form.submit();
    } catch (error) {
      setDisabled(true);
    }
  }

  const handlePersonalInfoSave = async () => {
    try {
      await personalInfoModalForm.validateFields();
      personalInfoModalForm.submit();
    } catch (error) {
      setDisabled(true);
    }
  }

  const handlePersonalInfoModalSubmit = (data: UpdateContactEnquiryPayload) => {
    const personalInfoData = merge({}, enquiryData?.personalInformation, data?.personalInformation);
    const isEmailSame = enquiryData?.personalInformation?.email === data?.personalInformation?.email;

    const submissionData = {
      id: enquiryData?.id, branchId: data?.branchId,
      personalInformation: personalInfoData,
      type: isEmailSame ? enquiryData?.type : "new",
      contactId: isEmailSame ? enquiryData?.contactId : undefined,
    };

    const previousPersonalInformation = {
      branchId: enquiryData.branch?.id,
      personalInformation: {
        email: enquiryData.personalInformation.email,
        firstName: enquiryData.personalInformation.firstName,
        lastName: enquiryData.personalInformation.lastName,
      },
    }

    const dataMatch = isEqual(data, previousPersonalInformation);
    if (!dataMatch) {
      mutate(submissionData, {
        onSuccess: (data) => {
          refetch().then(() => {
            const modifiedEnquiryData: ICrmEnquiryData = modifyEnquiryDataForDOB(data?.data);
            form.setFieldsValue(modifiedEnquiryData);
            setDestinationCountry(data?.data?.interestedService?.destinationCountry);
          })

          socket?.emit('server::enquiry-updated', {
            userId: user?.id as string,
            companyId: user?.companyId as string,
            activitiesType: 'enquiry',
            activitiesAction: 'updated',
            enquiryId: enquiryData.id,
            data: { currentPersonalInformation: submissionData, previousPersonalInformation },
          });

          queryClient.invalidateQueries({
            queryKey: ['contact-enquiry-activity'],
          });
          successNotificationHandler('Enquiry updated successfully');
        },
      });
    }
    handlePersonalInfoModalClose();
  }

  const handlePersonalInfoModalClose = () => {
    setPersonalInfoModalOpen(false);
    personalInfoModalForm.resetFields();
  }

  const openPersonalInfoEditModal = () => {
    setPersonalInfoModalOpen(true);
    personalInfoModalForm.setFieldsValue(enquiryData);
    cancelEdit();
  }

  const branchCountryDialCode = COUNTRIES.find(country => country.name.toLowerCase() === enquiryData?.branch?.address?.country?.toLowerCase())?.dial_code;


  useEffect(() => {
    const modifiedEnquiryData: ICrmEnquiryData = modifyEnquiryDataForDOB(enquiryData);
    form.setFieldsValue(modifiedEnquiryData);
    setPhone(enquiryData?.personalInformation?.phone);
    setCountryCode(enquiryData?.personalInformation?.countryCode ?? branchCountryDialCode ?? '+61');
    setDestinationCountry(enquiryData?.interestedService?.destinationCountry);
    setSelectedService(enquiryData?.interestedService?.interestedServiceId);
  }, [enquiryData, editMode, branchCountryDialCode, form])

  return (
    <>
      <div className="enquiry-view-form">
        <Card>
          <Row>
            <Col span={14}>
              <CustomizableUserCard
                firstName={enquiryData?.personalInformation?.firstName}
                lastName={enquiryData?.personalInformation?.lastName}
                email={enquiryData?.personalInformation?.email}
                nameClassName="enquiry-view-form__user-name"
              />
            </Col>
            <Col span={1} offset={1}>
              <Divider type="vertical" className="height-full" />
            </Col>
            <Col span={8} >
              <Row>
                <Col span={20}>
                  <Typography.Text className="text-secondary enquiry-view-form__branch-title">Branch</Typography.Text> <br />
                  <Typography.Text strong className="w-100" ellipsis={{ tooltip: true }}>{enquiryData?.branch?.name ?? 'N/A'}</Typography.Text>
                </Col>
                {canUpdate ?
                  <Col span={1} offset={1}>
                    <EditOutlined className="text-secondary enquiry-view-form__more-icon" onClick={openPersonalInfoEditModal} />
                  </Col> : null}
              </Row>
            </Col>
          </Row>
        </Card>

        <Row justify="space-between" className="margin-top-2">
          <Col>
            <Typography.Text className="enquiry-view-form__form-details__title">Form Details</Typography.Text>
          </Col>
          {canUpdate ?
            <Col>
              {!editMode ?
                <EditOutlined className="text-secondary enquiry-view-form__form-details__icon" onClick={() => setEditMode(true)} />
                :
                <CloseOutlined className="text-secondary enquiry-view-form__form-details__icon" onClick={cancelEdit} />
              }
            </Col>
            : null}
        </Row>

        <Row className="margin-top-2">
          <Form form={form}
            layout="vertical"
            className="w-100 enquiry-view-form__form-container"
            onFinish={handleFinish}
            onFieldsChange={onFieldsChange}
          >
            <EnquiryViewPersonalInfo
              form={form}
              editMode={editMode}
              phone={phone as string}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              onPhoneNumberChange={onPhoneNumberChange}
              validatePhoneNumber={validatePhoneNumber}
            />
            <EnquiryViewAddressInfo editMode={editMode} form={form} />
            <EnquiryViewInterestedService
              editMode={editMode}
              form={form}
              destinationCountry={destinationCountry}
              selectedService={selectedService}
              setService={handleServiceChange}
              setDestinationCountry={handleDestinationCountryChange}
            />
            <EnquiryViewTextAreaField name="comment" title={<Typography.Text strong>Additional Comments</Typography.Text>} editMode={editMode} placeholder={ENQUIRY_FORM.MORE_INFO} />
          </Form>
        </Row>

        {canUpdate && editMode ? (
          <Row justify="end" className="margin-top-2">
            <Space>
              <Button type="ghost" onClick={cancelEdit} >Cancel</Button>
              <Button type="primary" onClick={handleSubmit} disabled={disabled}>Update</Button>
            </Space>
          </Row>
        ) : null}

      </div>
      {isPersonalInfoModalOpen ?
        <EnquiryPersonalInfoModal
          visible={isPersonalInfoModalOpen}
          handleClose={handlePersonalInfoModalClose}
          form={personalInfoModalForm}
          handleSubmit={handlePersonalInfoModalSubmit}
          onFieldsChange={onFieldsChange}
          enquiryData={enquiryData}
          handleSave={handlePersonalInfoSave}
          disabled={disabled}
        /> : null}
    </>
  )
}

export { EnquiryViewForm };
