import { AUTH_MESSAGE, LABEL } from "@moxie/constants";
import { FormInstance, InputNumber } from "antd";
import Form from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import Radio from "antd/es/radio";
import { useState } from "react";

type AcademicMetricsOption = 'percentage' | 'gpa';

const AcademicMetricFields = ({ form, disabled = false, commonNamePath = ["interestedService", 'educationBackgrounds'] }: { form: FormInstance; disabled?: boolean; commonNamePath: string[]; }) => {
  const initialSelectedMetric: AcademicMetricsOption = form.getFieldValue([...commonNamePath, "academicMetric"])
  const [academicMetric, setAcademicMetric] = useState<AcademicMetricsOption>(initialSelectedMetric ?? 'percentage');

  return (
    <Row gutter={32}>
      <Col span={24}>
        <Form.Item initialValue={academicMetric} name={[...commonNamePath, "academicMetric"]}>
          <Radio.Group
            value={academicMetric}
            onChange={(e) => {
              setAcademicMetric(e.target.value);
              form.setFieldsValue({
                interestedService: {
                  academicMetric: e.target.value,
                  securedGpa: undefined,
                  totalGpa: undefined,
                  percentage: undefined
                }
              })
            }}
            disabled={disabled}
          >
            <Radio value="percentage">Percentage</Radio>
            <Radio value="gpa">GPA</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>

      <Col span={24} className="padding-top-2">
        {academicMetric === 'gpa' ? (
          <Row gutter={32}>
            <Col span={12}>
              <Form.Item
                name={[...commonNamePath, "securedGpa"]}
                rules={[
                  { min: 0, max: 10, type: 'number' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const totalGpa = getFieldValue('totalGpa');
                      if (!value && totalGpa) {
                        return Promise.reject(
                          AUTH_MESSAGE.SECURED_GPA_REQUIRED
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                validateFirst
                label="Secured GPA"
              >
                <InputNumber
                  readOnly={disabled}
                  className="full-width"
                  data-testid="crm-contactdetails-gpa"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={[...commonNamePath, "totalGpa"]}
                rules={[
                  {
                    min: 0,
                    max: 10,
                    type: 'number',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const securedGpa = getFieldValue([...commonNamePath, 'securedGpa']);
                      if (securedGpa && !value) {
                        return Promise.reject(
                          AUTH_MESSAGE.TOTAL_GPA_REQUIRED
                        );
                      }
                      if (value < securedGpa) {
                        return Promise.reject(AUTH_MESSAGE.GREATER_GPA);
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                validateFirst
                label={LABEL.TOTAL_GPA}
              >
                <InputNumber
                  className="full-width"
                  readOnly={disabled}
                  data-testid="crm-contactdetails-totalgpa"
                />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Form.Item
            name={[...commonNamePath, "percentage"]}
            label={LABEL.PERCENTAGE}
            rules={[{ min: 0, max: 100, type: 'number' }]}
            validateFirst={true}
          >
            <InputNumber
              readOnly={disabled}
              className="full-width"
              data-testid="crm-contactdetails-percentage"
            />
          </Form.Item>
        )}
      </Col>
    </Row >
  )
}
export default AcademicMetricFields;
