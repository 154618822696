import React, { useMemo } from "react";
import Card from "antd/es/card";
import Form, { FormInstance } from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import Select from "antd/es/select";
import Typography from "antd/es/typography";
import Input from "antd/es/input";
import { useFetchInterestedServices } from "@crm/libs/hooks/interested-services/useFetchInterestedServices";
import { ICrmInterestedService } from "@model/index";
import { NamePath } from "antd/lib/form/interface";
import { AvailableServices } from "./enquiry-view-form";
import DatePicker from "antd/es/date-picker";
import { LanguageTestScores } from "@crm/src/pages/office-visit-form/interested-service-info/language-test-scores-fields";
import { LastEducationCompletedFields } from "@crm/src/pages/office-visit-form/interested-service-info/last-education-completed-fields";

interface EnquiryInterestedServiceProps {
  editMode: boolean;
  form: FormInstance;
  destinationCountry: string[] | undefined;
  selectedService: string | undefined;
  setDestinationCountry: (value: string[]) => void;
  setService: (value: string) => void;
}

interface ServiceFormFieldProp {
  key: 'countries' | 'degreeLevels' | 'type' | 'interests' | 'intake';
  name: NamePath;
  inputType: 'checkableTag' | 'select' | 'input' | 'intakeDate' | 'dateRange';
  label: string;
  placeholder?: string;
  disabled?: boolean;
  mode?: "multiple" | "tags" | undefined;
}

const serviceFormFields: Record<AvailableServices, (ServiceFormFieldProp)[]> = {
  'Education': [
    { key: "countries", name: ['interestedService', 'destinationCountry'], inputType: "select", label: "Destination Country", mode: "multiple" },
    { key: "type", name: ['interestedService', 'type'], inputType: "input", label: "What discipline are you interested in?", placeholder: "Enter your discipline" },
    { key: 'intake', name: ['interestedService', 'intake'], inputType: "intakeDate", label: "Which intake are you aiming for?", placeholder: "Enter your intake" },
    { key: "degreeLevels", name: ['interestedService', 'interests'], inputType: "select", label: "Which degree level are you applying for?", placeholder: "Please select degree", mode: "multiple" },
  ],
  'Migration': [
    { key: "countries", name: ['interestedService', 'destinationCountry'], inputType: "select", label: "Destination Country", disabled: true, mode: "multiple" },
    { key: "interests", name: ['interestedService', 'interests'], inputType: "select", label: "Which service do you want to discuss?", placeholder: "Please select the service", mode: "multiple" },
    { key: "type", name: ['interestedService', 'type'], inputType: "input", label: "What is your current visa?", placeholder: "Please enter current visa category" },
  ],
  'Insurance': [
    { key: "countries", name: ['interestedService', 'destinationCountry'], inputType: "select", label: "Destination Country", mode: "multiple" },
    { key: "interests", name: ['interestedService', 'interests'], inputType: "select", label: "Which health cover are you interested in? ", placeholder: "Please select health coverage", mode: "multiple" },
    { key: "type", name: ['interestedService', 'type'], inputType: "select", label: "What is the policy type?", placeholder: "Please select the policy type" },
  ],
  "Test Prep/Booking": [
    { key: "countries", name: ['interestedService', 'destinationCountry'], inputType: "select", label: "Destination Country", disabled: true, mode: "multiple" },
    { key: "interests", name: ['interestedService', 'interests'], inputType: "select", label: "Which test are you planning to take?", mode: "multiple" },
    { key: "type", name: ['interestedService', 'type'], inputType: "select", label: "Which service are you interested in?" },
  ],
  'Not Sure': [],
}


const EnquiryViewInterestedService = ({ editMode, form, destinationCountry, setDestinationCountry, selectedService, setService }: EnquiryInterestedServiceProps) => {
  const { interestedServices: interestedServicesData, isLoading } = useFetchInterestedServices();
  const interestedServices = interestedServicesData.sort((a, b) => a.payload.level - b.payload.level);

  const filteredData = useMemo(() => {
    if (!selectedService) {
      return undefined;
    }
    return interestedServices.find((data) => data.id === selectedService);
  }, [selectedService, interestedServices])

  const handleServiceChange = (value: string) => {
    const selectedServiceData = interestedServices.find((data) => data.id === value);
    setDestinationCountry([selectedServiceData?.payload?.countries?.at(0) as string]);
    form.setFieldsValue({
      interestedService: {
        interests: [],
        type: undefined,
        destinationCountry: [selectedServiceData?.payload?.countries?.at(0)],
        intake: undefined,
        educationBackgrounds: {
          studyPeriod: undefined,
          percentage: undefined,
          securedGpa: undefined,
          totalGpa: undefined,
          completedDegree: undefined,
          degreeTitle: undefined,
          academicMetric: undefined,
        },
        testScore: {
          reading: undefined,
          writing: undefined,
          listening: undefined,
          speaking: undefined,
          overallScore: undefined,
        },
      },
    })
    setService(value);
  }

  return (
    <Card loading={isLoading} className="margin-top-2 w-100" title={<Typography.Text strong>Interested Service</Typography.Text>} headStyle={{ background: "none" }}>
      <Row>
        <Col span={24}>
          <Form.Item name={['interestedService', 'interestedServiceId']} label="Service">
            <Select placeholder="Select a service" showSearch disabled={!editMode} onChange={handleServiceChange}>
              {interestedServices?.map((service: ICrmInterestedService) => {
                return (
                  <Select.Option
                    key={service?.id}
                    value={service?.id}
                  >
                    {service?.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          {
            selectedService && filteredData ?
              serviceFormFields[filteredData?.name as AvailableServices]?.map((field, index) =>
                Object.keys(field)?.length ?
                  (
                    <Col span={24} key={index}>
                      <Form.Item name={field.name} label={field.label}>
                        {
                          field.inputType === 'select' && field.key !== 'intake' ?
                            <Select allowClear showArrow disabled={!editMode} mode={field?.mode ?? undefined}>
                              {filteredData?.payload[field.key]?.map((item: string, index: number) => (
                                <Select.Option key={index} value={item}>
                                  {item}
                                </Select.Option>
                              ))}
                            </Select>
                            : field.inputType === 'dateRange' ?
                              <DatePicker.RangePicker
                                className="full-width"
                                picker="date"
                                format={'DD/MM/YYYY'}
                                placeholder={["DD/MM/YYYY", "DD/MM/YYYY"]}
                                disabled={!editMode}
                              />
                              : field.inputType === 'input' ?
                                <Input className={!editMode ? 'enquiry-view-form__cursor-default' : ''} placeholder={field.placeholder} readOnly={!editMode} />
                                : field.inputType === 'intakeDate' && field.key === 'intake' ?
                                  <DatePicker
                                    className={!editMode ? 'note-title-input enquiry-view-form__cursor-default full-width' : 'note-title-input full-width'}
                                    format="MMMM YYYY"
                                    picker="month"
                                    allowClear={true}
                                    disabled={!editMode}
                                  />
                                  : null
                        }
                      </Form.Item>
                    </Col>
                  ) : null
              ) : null
          }

          {filteredData && (["Education", "Test Prep/Booking"].includes(filteredData.name)) ?
            <LastEducationCompletedFields form={form} interestedServiceData={filteredData} disabled={!editMode} enquiryForm={true} commonNamePath={["interestedService", 'educationBackgrounds']} />
            : null}

          {filteredData && filteredData?.name === "Education" ? <LanguageTestScores form={form} hideTitle={true} disabled={!editMode} enquiryForm={true} /> : null}

        </Col>
      </Row>
    </Card>
  )
}
export { EnquiryViewInterestedService };
