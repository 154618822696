import { ICrmInterestedService, IGetPopupContainer } from "@model/index"
import DatePicker from "antd/es/date-picker";
import Form, { FormInstance } from "antd/es/form";
import { Row } from "antd/es/grid";
import Input from "antd/es/input";
import Select from "antd/es/select";
import Typography from "antd/es/typography";
import AcademicMetricFields from "./academic-metric-fields";

interface IProps {
  form: FormInstance;
  interestedServiceData: ICrmInterestedService | undefined;
  commonNamePath?: string[];
  disabled?: boolean;
  enquiryForm?: boolean;
}

const { RangePicker } = DatePicker;

export const LastEducationCompletedFields = ({ form, interestedServiceData, disabled = false, enquiryForm = false, commonNamePath = ["interestedService", 'educationBackgrounds'] }: IProps) => {
  return (
    <>
      <Row className="margin-bottom-2">
        <Typography.Text
          strong
          className={`${enquiryForm ? 'font-regular font-bold margin-top-1' : 'enquiry-form__additional-info__title'}`}
        >
          Last Education Completed
        </Typography.Text>
      </Row>

      <div id="enquiry-form-degree-list" className="relative">
        <Form.Item
          name={[...commonNamePath, 'completedDegree']}
          label="Degree Level"
          rules={[
            {
              required: true,
              message: "Please select degree level"
            }
          ]}
        >
          <Select placeholder="Please select degree" showArrow showSearch
            optionFilterProp="label"
            getPopupContainer={(): IGetPopupContainer => document.getElementById('enquiry-form-degree-list')}
            disabled={disabled}
          >
            {interestedServiceData?.payload?.completedDegrees?.map((level: string, index: string) => (
              <Select.Option key={index} value={level} label={level}>
                {level}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <Form.Item
        name={[...commonNamePath, 'degreeTitle']}
        label="Degree Title"
      >
        <Input placeholder="Enter your degree" readOnly={disabled} />
      </Form.Item>

      <Form.Item name={[...commonNamePath, "studyPeriod"]} label="Study Period">
        <RangePicker
          className="full-width"
          picker="date"
          popupStyle={{ marginLeft: "1rem" }}
          format={'DD/MM/YYYY'}
          placeholder={["DD/MM/YYYY", "DD/MM/YYYY"]}
          disabled={disabled}
        />
      </Form.Item>


      <AcademicMetricFields form={form} disabled={disabled} commonNamePath={commonNamePath} />
    </>
  )
}
