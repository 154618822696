import { updateEnquiryData, updateOnlineFormEnquiryData } from '@crm/services.api';
import { UpdateContactEnquiryPayload } from '@model/crm/enquiry-form.model';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { crmErrorHandler } from 'libs/shared/src/crm-modules/shared';

export const useUpdateEnquiryData = (triggerRefresh?: () => any) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['edit-enquiry-data'],
    mutationFn: (data: Partial<UpdateContactEnquiryPayload>) => updateEnquiryData(data.id as string, data),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['contact-enquiry-activity', data.data.id],
      });
      if (triggerRefresh) triggerRefresh();
    },
    onError: (error) => {
      crmErrorHandler(error);
    },
  });
};

export const useUpdateEnquiryFormData = (triggerRefresh?: () => any) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['edit-online-form-enquiry-data'],
    mutationFn: (data: Partial<UpdateContactEnquiryPayload>) => updateOnlineFormEnquiryData(data.id as string, data),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['contact-enquiry-activity', data.data.id],
      });
      if (triggerRefresh) triggerRefresh();
    },
    onError: (error) => {
      crmErrorHandler(error);
    },
  });
};
