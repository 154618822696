import React, { useState } from 'react';
import {
  BRANCH_RESPONSE_MESSAGE,
  CONFIRM_MESSAGE,
  DELETE_BTN,
  PAGE_HEADER_MENU_OFFICE_BRANCH,
  TEXT,
} from '@moxie/constants';
import OfficeTableList from './office-table-list';
import { getOneOfficeBranch } from '@crm/services.api';
import { OfficeDrawer } from './office-drawer';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { officeBranchActions } from '@crm/core';
import {
  capitalizeTrimWord,
  PageHeaderTitle,
  popConfirm,
  successNotificationHandler,
} from '@moxie/shared';
import { FormItemProps } from 'antd';
import { IOfficeBranchCRM, IUpdateOfficeCrm } from '@model/branch';
import { PageHeaderMenu, crmErrorHandler } from '../../shared';
import { useOfficeBranchMutation, useOfficeBranchUpdate } from "@crm/libs/hooks/branches"

const OfficeTableComponent: React.FC = () => {
  const [form] = useForm();
  const [initialData, setInitialData] = useState<IOfficeBranchCRM>();
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const [refreshTable, setRefreshTable] = useState(true);
  const [nameValidationStatus, setNameValidationStatus] = useState<
    FormItemProps['validateStatus']
  >('');
  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  const addOfficeBranch = useOfficeBranchMutation();
  const updateOfficeBranch = useOfficeBranchUpdate();

  const showDrawer = async (id?: string) => {
    if (id) {
      try {
        const resData = await getOneOfficeBranch(id ? id : '');
        setInitialData(resData);
        setIsEdit(true);
      } catch (err) {
        crmErrorHandler(err);
      }
    } else {
      setInitialData(undefined);
    }
    setVisible(true);
    setDisabled(true);
  };

  const handleClose = () => {
    setVisible(false);
    setDisabled(true);
    form.resetFields();
    setIsEdit(false);
    triggerRefresh();
  };

  const handleSubmit = async (data: IOfficeBranchCRM) => {
    setDisabled(true);
    let formData;
    const { name, address, ...restData } = data;
    const capitalizedName = capitalizeTrimWord(name);

    if (!restData?.contactPersonId) restData.contactPersonId = null;
    restData.email = restData?.email?.replace(/ /g, '');

    if (initialData?.id) {
      if (address?.country === undefined) address!.country = '';
      formData = {
        ...restData,
        id: initialData.id,
        name: capitalizedName,
        address: {
          ...address,
          id: initialData?.address?.id
        }
      } as IUpdateOfficeCrm;
      if (initialData?.name === capitalizedName) {
        delete formData.name;
      }
      updateOfficeBranch.mutate(formData, {
        onSuccess: () => {
          successNotificationHandler(BRANCH_RESPONSE_MESSAGE.BRANCH_UPDATE);
          setVisible(false);
          setIsEdit(false);
          form.resetFields();
          triggerRefresh();
        },
        onError: (error) => {
          setVisible(true);
          crmErrorHandler(error);
        }
      })
    } else {
      formData = { ...restData, address, name: capitalizedName };
      addOfficeBranch.mutate(formData, {
        onSuccess: () => {
          successNotificationHandler(BRANCH_RESPONSE_MESSAGE.BRANCH_GENERATE);
          form.resetFields();
          setVisible(false);
          triggerRefresh();
        },
        onError: (error) => {
          setVisible(true);
          crmErrorHandler(error);
        }
      })
    }
  };

  const handleFormSubmitViaBtn = () => {
    form.submit();
    setDisabled(true);
  };

  const handleFieldsChange = (allFields: string | any[]) => {
    for (const fields of allFields) {
      fields.errors.length <= 0 ? setDisabled(false) : setDisabled(true);
    }
  };
  const handleDelete = async (id: string) => {
    popConfirm(DELETE_BTN, `${CONFIRM_MESSAGE.DELETE_BRANCH}`, async () => {
      try {
        dispatch(
          officeBranchActions.deleteOfficeBranchRequest(id, triggerRefresh)
        );
      } catch (err) {
        crmErrorHandler(err);
      }
      triggerRefresh();
    });
  };

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.OFFICES} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_OFFICE_BRANCH} />

      <OfficeDrawer
        visible={visible}
        onClose={handleClose}
        disabled={disabled}
        setDisabled={setDisabled}
        form={form}
        initialData={initialData}
        handleFormSubmit={handleFormSubmitViaBtn}
        isEdit={isEdit}
        handleSubmit={handleSubmit}
        handleFieldsChange={handleFieldsChange}
        nameValidationStatus={nameValidationStatus}
        setNameValidationStatus={setNameValidationStatus}
      />

      <OfficeTableList
        showDrawer={showDrawer}
        deleteData={handleDelete}
        refreshTable={refreshTable}
      />
    </div>
  );
};

export { OfficeTableComponent };
