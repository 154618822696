export const ADD_SUCCESS = 'Data added successfully.';
export const UPDATE_SUCCESS = 'Data updated successfully.';
export const DELETE_SUCCESS = 'Data deleted successfully.';
export const ARCHIVE_SUCCESS = 'Data archived successfully.';
export const RESTORE_SUCCESS = 'Data restored successfully.';
export const FETCH_SUCCESS = 'Data fetched successfully.';
export const NO_FOLLOWERS = 'No Followers';
export const NO_ASSIGNEES = 'No Assignees';
export const FILE_SIZE_EXCEED = 'File size should not be more than 10 MB';
export const FILE_CORRUPT = 'File is corrupt';
export const TOTAL_FILE_SIZE_EXCEED = 'Total file size upload should not exceeded more than 50 MB';

export const AUTH_MESSAGE = {
  REGISTER: 'Please wait for confirmation & domain to Login.',
  REGISTER_EMAIL_TAKEN: 'Email is already registered.',
  REGISTER_EMAIL_AVAILABLE: 'Email is available to be registered.',
  INVALID_FIRST_NAME: 'Please enter valid First name.',
  INVALID_LAST_NAME: 'Please enter valid Last name.',
  INVALID_EMAIL: 'Please input valid email.',
  INVALID_WEB_URL: 'Please input valid web url.',
  PASSWORD_REQUIRED: 'Password must be provided.',
  PASSWORD_MATCH: 'Passwords must match.',
  PASSWORD_NOT_MATCH: 'Please enter the correct password.',
  PASSWORD_MIN_LENGTH_ERROR: 'Password must be at least 6 characters long',
  DEACTIVATED: 'Your account is deactivated',
  NEW_PASSWORD_CANNOT_BE_SAME_AS_OLD:
    'New password cannot be same as old password',
  OLD_NEW_PASSWORD_NOT_MATCH: "Old password doesn't match",
  EMAIL_NOT_MATCH: 'Email address does not exist.',
  PASSWORD_UPDATE_SUCCESS: 'Password has been updated.',
  INVALID_TOKEN: 'Invalid Token',
  INVALID_CREDENTIAL: 'Invalid Credentials',
  INVALID_ACTION: 'Invalid Action',
  CANNOT_CREATE_SUPER_ADMIN: 'Cannot create super admin',
  CANNOT_DELETE_SUPER_ADMIN: 'Cannot delete super admin',
  CANNOT_DELETE_SUPERADMIN_ROLE: 'Cannot delete super admin role',
  CANNOT_UPDATE_SUPER_ADMIN: 'Cannot update super admin',
  CANNOT_DELETE_ONLY_SUPERADMIN: 'Cannot delete last super admin',
  CANNOT_UPDATE_ONLY_SUPERADMIN: 'Cannot update last super admin',
  LOGOUT: 'Logged out Successfully.',
  DUPLICATE_EMAIL: "There are duplicate email id's in the excel sheet.",
  FORGET_PASSWORD:
    'Please check your inbox. We have sent you a link to reset your password.',
  FORGET_FAILED: 'Something went wrong. Please try again.',
  ACTIVATE_ACCOUNT:
    'Please check your inbox. We have sent you a link to activate your account.',
  FORGET_PASSWORD_LIMIT:
    'You have reached this limit, You will need to wait 24 hours before trying to reset your password again.',
  COMPANY_DOMAIN_EXISTS: 'Company Domain is already registered.',
  COMPANY_CODE_EXISTS: 'Company Code is already registered.',
  COMPANY_DOMAIN_NAME_REQUIRED: 'Company domain name is required.',
  COMPANY_CODE_REQUIRED: 'Company Code is required.',
  UNAUTHORIZED: 'You are not authorized to perform this operation',
  USER_VERIFIED: 'Your account has been verified.',
  USER_NOT_VERIFIED: 'Your account is not verified.',
  DOWNLOAD_FAIL: 'Error while downloading file!',
  DATA_NOT_FOUND: 'Data not found!',
  PRODUCT_DATA_NOT_FOUND: 'Data not found!',
  HEADER_VALIDATION_FAIL: 'Headers missing! please recheck and upload',
  FILEEXT_VALIDATION: 'Only excel files are allowed!',
  ASSIGNE_NOT_FOUND: 'Assignee not found!',
  FOLLOWER_NOT_FOUND: 'Follower not found!',
  DONT_EXIST: "User doesn't exist",
  COMPANY_PROFILE_UPDATED: 'Profile has been updated successfully.',
  COMPANY_DOMAIN_NOT_MATCH: 'You are not authorized to access this site.',
  SERVICE_DOWN: 'Service down, Please try again later.',
  SESSION_EXPIRED: 'Session Expired, Please log in again.',
  SOMETHING_FAILED: 'Something went wrong, Try again later.',
  GREATER_GPA: 'Must be greater than or equal to Secured GPA.',
  SECURED_GPA_REQUIRED: 'Please enter Secured GPA',
  TOTAL_GPA_REQUIRED: 'Please enter Total GPA',
  NAME_EXIST: 'This name already exists',
  EMAIL_EXIST: 'Email already exists',
  FETCH_SERVICE_FAIL: 'Failed to load services',
  ACCESS_FORBIDDEN: 'You dont have access to this resource!',
  DONT_HAVE_PERMISSION_TO_DELETE_SUPERADMIN:
    'You dont have access to delete superAdmin',
  FORBIDDEN_MESSAGE_PERMISSION: "You don't have permission to ",
  CANNOT_DELETE_ROLE_WITH_USERS: 'Cannot delete role with user',
  USER_PASSWORD_VERIFIED: 'You have already set the password.',
  No_DATA_AVAILABLE: 'No data available',
};

export const FORBIDDEN_MESSAGE = {
  LEAD_DOCUMENT: 'Document upload is forbidden.',
  PROFESSIONAL_EXPERIENCE: 'Action is forbidden.',
};

export const LEAD_CONVERSION_MESSAGE = {
  LEAD_TO_PROSPECT: ' is now a Prospect.',
  LEAD_PROSPECT_TO_CLIENT: ' is now a Client.',
};

export const LEAD_FORM_RESPONSE_MESSAGE = {
  LEADS_FORM_NOT_FOUND: 'Lead forms cannot be found.',
  ALL_LEADS_FORM: 'All Leads forms.',
  NOT_FOUND: 'Lead data not found.',
  ALREADY_EXISTS: 'Data already exists.',
  SPECIFIC_LEAD_FORM_NOT_FOUND: 'Lead form with this ID cannot be found.',
  LEAD_FORM_GENERATE: 'Lead form generated successfully.',
  LEAD_FORM_NOT_CREATED: 'Lead Form could not be created.',
  LEAD_FORM_UPDATE: 'Lead form updated successfully.',
  LEAD_FORM_UPDATE_FAILED: 'Lead form could not be updated.',
  CONTACT_TITLE_TEXT: 'Contacts - Agent ApplyIMS',
  LEAD_FORM_DELETE: 'Lead form has been deleted.',
  LEAD_FORM_DELETE_FAILED: 'Lead form deletion failed.',
  AGREE_TO_TERMS: 'Please agree to terms to submit.',
  EMAIL_ALREADY_REGISTERED: 'This email is already registered.',
  LEAD_FORM_USED: 'Lead form already used and cannot be deleted.',
};

export const INTERESTED_SERVICE_RESPONSE_MESSAGE = {
  PRODUCT_ALREADY_EXIST:
    'This product is already registered as an interested service',
  WORKFLOW_NOT_FOUND: 'Workflow not found',
  LEAD_NOT_FOUND: 'Lead could not be found',
  INSTITUTE_NOT_FOUND: 'Institution could not be found',
  PRODUCT_NOT_FOUND: 'Product could not be found',
};

export const PRODUCT_EDUCATION_RESPONSE_MESSAGE = {
  NOT_FOUND: 'Product education requirements could not be found!',
  PRODUCT_NOT_FOUND: 'Product could not be found',
};

export const PRODUCT_LANGUAGE_TEST_SCORE_RESPONSE_MESSAGE = {
  NOT_FOUND: 'Product language test score requirements could not be found!',
  TEST_NAME_ALREADY_EXISTS: 'Test already exists.',
};

export const PRODUCT_OTHER_TEST_SCORE_RESPONSE_MESSAGE = {
  NOT_FOUND: 'Product other test score requirements could not be found!',
};
export const LEAD_OTHER_TEST_SCORES = {
  ADD_SUCCESS: 'Other test score added successfully',
  DELETE_SUCCESS: 'Other test score deleted successfully.',
  UPDATE_SUCCESS: 'Other test score updated successfully.',
  NOT_FOUND: 'Data could not be found.',
  ALREADY_EXISTS_ERROR: 'Already exists',
  VALUE_LIMIT_ERROR: 'Score must be between 400 to 1600',
  VALUE_LIMIT_ERROR_GRE: 'Score must be between 260 to 340',
  VALUE_LIMIT_ERROR_GMAT: 'Score must be between 200 to 800',
};
export const LEAD_DOCUMENT_ADD = {
  UPLOAD_ERROR: 'Failed to upload data.',
  UPLOAD_SUCCESS: 'Uploaded Successfully.',
  FILE_SIZE_LIMIT: 'File must be less then 25 MB',
  INVALID_DATA: 'Invalid Data',
  CONTACT_NOT_FOUND: 'Contact not found.',
  DOCUMENT_NOT_FOUND: 'Document not found.',
};

export const LEAD_IMPORT_MESSAGE = {
  HEADER_VALIDATION_FAIL: 'The file does not match the required template',
  FILEEXT_VALIDATION: 'Invalid file format',
  IMPORT_SUCCESS: 'Data imported successfully',
  IMPORT_FAIL: 'Failed to import data',
  REQUIRED_FIELD_MESSAGE: 'Required field must be entered',
};

export const USER_FORM_RESPONSE_MESSAGE = {
  USER_NOT_ADDED: 'User could not be added.',
  USER_ADDED: 'User added successfully.',
  USER_EXISTS: 'User already exists.',
  USER_NOT_FOUND: 'User not found.',
  USER_FOUND: 'User found.',
  USER_PERMISSIONS_FOUND: 'User permissions found.',
  USER_PERMISSIONS_UPDATED: 'User permissions updated successfully.',
  CRM_PERMISSION_NOT_FOUND: 'CRM permission not found.',
  ALL_USER_DATA: 'User list retrieved successfully.',
  USER_UPDATED: 'User updated successfully.',
  NOT_ALLOWED: 'This request is not allowed',
  USER_NOT_UPDATED: 'User update failed.',
  PROFILE_IMAGE_UPLOADED: 'Profile Image uploaded.',
  CANNOT_DELETE_SELF: 'You cannot delete yourself',
  USER_DELETED_SUCCESSFULLY: 'User deleted successfully',
  USER_DEACTIVATED_SUCCESSFULLY: 'User deactivated successfully',
  USER_ACTIVATED_SUCCESSFULLY: 'User activated successfully',
  NO_PERMISSION_TO_UPDATE_USER_TO_SUPERADMIN_USER:
    'You do not have permission to create user with Super Admin role',
  NO_PERMISSION_TO_CHANGE_ROLE_OF_SUPERADMIN:
    'You do not have the permission to change the role of a Super Admin',
  NO_PERMISSION_TO_CHANGE_OWN_ROLE: 'You can not change your own role',
  CANNOT_DEACTIVATE_YOURSELF: 'You can not deactivate yourself',
  CANNOT_ACTIVATE_YOURSELF: 'You can not activate yourself',
  ALREADY_DEACTIVATED: 'User already deactivated',
  ALREADY_ACTIVATE: 'User already active',
  BRANCH_NOT_FOUND: 'Branch not found',
};

export const COMPANY_RESPONSE_MESSAGE = {
  COMPANY_LIST: 'Company list.',
  COMPANY_NOT_FOUND: 'Company NOT Found.',
  UPDATE_SHARING_RATIO_SUCCESS: 'Sharing ratio updated successfully',
  UPDATE_EXPIRATION_DATE_SUCCESS: 'Expiration Date updated successfully',
  UPDATE_SUCCESS: 'Company data updated successfully',
};

export const ROLE_RESPONSE_MESSAGE = {
  ROLE_NOT_FOUND: 'Role not found.',
  ROLE_ADDED: 'Role has been added successfully',
  ROLE_UPDATE: 'Role has been updated successfully',
  ROLE_DELETE: 'Role has been deleted successfully',
  ROLE_ALREADY_EXISTS: 'Role already exists',
};

export const INSTITUTION_RESPONSE_MESSAGE = {
  NOT_FOUND: 'Institutions cannot be found.',
  CONNOT_FIND_INSITUTIONS_FOR_SERVICE:
    'Can not find instutitions for given serviceId',
  ID_NOT_GIVEN: 'Institution ID not given',
  ADD_SUCCESS: 'Institution Added Successfully!',
  SYNC_SUCCESS: 'Institution Synced Successfully!',
  DELETE_SUCCESS: 'Institution Deleted Successfully!',
  UPDATE_SUCCESS: 'Institution Updated Successfully!',
  UPLOAD_FAIL: 'Upload Failed!',
  CANNOT_DELETE: 'Institution has clients so it can not be deleted.',
  SYNC_FAILED: 'Failed to sync the institution !!',
  SYNC_ALREADY: 'Institution already synced!',
  RESTRICT_ADD_INST: 'User is not authorized to add Institution.',
  RESTRICT_SYNC_INST: 'User is not authorized to sync Institution.',
  DATA_NOT_FOUND: 'Institution data not found.',
  INVALID_DATA: 'Invalid data.',
  INSTITUTION_ALREADY_EXISTS: 'Insitution with given name already exists',
};

export const PRODUCT_RESPONSE_MESSAGE = {
  DELETE_SUCCESS: 'Product Deleted Successfully!',
  NO_PRODUCT_TYPES_FOR_SERVICE_ID:
    'No product_types found for given service_id',
  NOT_FOUND: 'Product cannot be found.',
  ADD_SUCCESS: 'Product added successfully.',
  PRODUCT_SUB_TYPES_NOT_FOUND:
    'Product subtypes not found for given product_type id',
  UPDATE_SUCCESS: 'Product updated successfully.',
  ARCHIVE_SUCCESS: 'Product archived successfully.',
  RESTORE_SUCCESS: 'Product restored successfully.',
  ADD_FAIL: 'Failed to add Product.',
  PRODUCT_COMPARED_LIST: 'Product compared list retrieved successfully.',
  MAX_COMPARED_PRODUCT: 'Maximum number of products to be compared is 5.',
  INTAKE_NOT_FOUND: 'Product Intake Data not found.',
  CANNOT_DELETE: 'Product has clients so it can not be deleted.',
  SYNC_FAILED: 'Failed to sync the product !!',
  SYNC_SUCCESS: 'Product Synced Successfully!',
  SYNC_ALREADY: 'Product already synced!',
  RESTRICT_ADD_PRODUCT: 'User is not authorized to add Product.',
  RESTRICT_SYNC_PRODUCT: 'User is not authorized to sync Product.',
  ERROR_PRODUCT: 'Invalid Data',
  INTAKE_ALREADY_EXISTS: 'Intake already exists.',
  PRODUCT_TYPE_ALREADY_EXISTS: 'Product type already exists.',
  PRODUCT_TYPE_NOT_FOUND: 'Product type not found.',
  PRODUCT_NOT_FOUND: 'Product not found.',
  PRODUCT_SUB_TYPE_NOT_FOUND: 'Product sub type not found.',
  NOT_FOUND_LIST: 'Products are not found',
};

//verification msg
export const VERIFICATION_SUCCESSFUL = 'Your email has been verified.';
export const ERROR_MESSAGE = 'Error occurred';
export const VERIFICATION_FAILED =
  "Your email couldn't be verified, Please try again later.";
export const RESEND_VERIFICATION_SUCCESS =
  'Please checky you inbox, verification email has been sent.';

export const MSG_PASSWORD_UPDATED = 'Password updated successfully.';
export const LEAD_ID_NOT_PROVIDED = 'Lead Id not provided';
//required msg
export const CHECKLISTS_REQUIRED = 'Select one or more checklist items.';
export const FORM_NAME_REQUIRED = 'Form name is required.';
export const MINIMUM_SIZE_OPTION_REQUIRED = 'Minimum two options are required';
export const EMAIL_REQUIRED = 'Email is required.';
export const PASSWORD_REQUIRED = 'Password is required.';
export const CONFIRM_PASSWORD_REQUIRED = 'Confirm password is required.';
export const CATEGORY_REQUIRED = 'Category name is required';
export const CATEGORY_NAME_MAX = 'Category name must be up to 30 characters';
export const LABEL_MAX = 'Label must be up to 30 characters';
export const LABEL_REQUIRED = 'Label is required';
export const OPTION_REQUIRED = 'Option is required';
export const LABEL_OPTION_REQUIRED = 'Label option is required';
export const LABEL_OPTION_SHOULD_NOT_BE_SIMILAR =
  'Label option name cannot be similar';
export const LABEL_OPTION_NUMBER_REQUIRED =
  'At least two label option is required';
export const STAGE_NAME_REQUIRED = 'Stage Name is Required';
export const STAGE_NAME_TOO_LONG = 'Stage Name too long';
export const STAGE_MAX_CHARACTER_LIMIT =
  'Stage Name must be up to 30 characters.';

//validation msg
export const CURRENT_PASSWORD_REQUIRED = 'Please enter current password';
export const NEW_PASSWORD_REQUIRED = 'Please enter new password';
export const CONFIRM_NEW_PASSWORD_REQUIRED = 'Please enter new password again';
export const CONFIRM_PASSWORD_DIDNT_MATCH =
  'The two passwords that you entered do not match.';
export const PHONE_NUMBER_INVALID = 'Invalid phone number.';
export const PHONE_TEN_DIGIT_ONLY = 'Please enter valid 10 digit phone number';
export const ALPHANUMERIC_REQUIRED =
  'Please enter alphanumeric characters only';
export const UNIQUE_OPTION_NAME = 'Option name should be unique';
export const DOMAIN_INVALID =
  'Please enter a valid domain name. It should contain only alphabets.';
export const CODE_INVALID =
  'Please enter a valid name. It should contain only alphabets.';
export const DOMAIN_TAKEN = 'This domain name has been already taken.';
export const COMPANY_CODE_TAKEN = 'This code has been already taken.';
export const INPUT_NUMBER_INVALID = 'Please input number';
export const FILE_TYPE_INVALID = 'You can only upload JPG/PNG file!';
export const FILE_SIZE_INVALID = 'Image must smaller than 2MB!';
export const NO_FILES_SELECTED = 'No files are selected';
export const NO_FILES_AVAILABLE = 'No files are available';
export const MAXIMUM_VALUE_EXCEED_ERROR = 'Maximum value should not exceed';
export const OVERALL_SCORE_MAXIMUM_VALUE_EXCEED_ERROR =
  'Maximum value of overall score for test pte should not exceed';
export const MINIMUM_VALUE_EXCEED_ERROR =
  'Minimum value should not be less than 0';
export const OVERALL_SCORE_MINIMUM_VALUE_EXCEED_ERROR =
  'Minimum value of overall score for test pte should not be less than 0';
export const INVALID_FILE_NAME = 'Invalid Filename.';
export const CATEGORY_OPTION_REQUIRED =
  'Please input option or delete this field.';
export const FILE_NAME_REQUIRED = 'Filename is Required.';
export const FILE_NAME_TOO_LONG = 'Filename too long.';
export const FILE_NAME_MAXIMUM = 'Filename must be up to 50 characters.';
export const BULK_FILE_NAME_MAXIMUM = 'Max 50 characters allowed';
export const MAXIMUM_NUMBER_OF_FILES = 'Maximum Number of Files Allowed: ';
export const MAXIMUM_SIZE_OF_FILES = 'Maximum Total Size of Files Allowed: ';
export const MAXIMUM_SIZE_OF_FILE = 'Maximum Size of a Single File Allowed.';
export const FORM_NAME_MAXIMUM = 'Form name must be up to 50 characters.';

//CONFIRM QUESTIONS
export const CONFIRM_MESSAGE = {
  ARCHIVE_LEAD: 'Do you want to archive',
  ARCHIVE_PRODUCT: 'Do you want to archive this product?',
  DELETE_LEAD: 'Do you want to delete',
  DELETE_COMPANY: 'Do you want to delete this Company?',
  DISABLE_COMPANY: 'Do you want to disable this Company?',
  REJECT_COMPANY: 'Do you want to reject this Company?',
  DELETE_PRODUCT: 'Do you want to delete this product?',
  DELETE_LABEL: 'Do you want to this this label?',
  DELETE_PRODUCT_FEE: 'Do you want to delete this product fee?',
  DELETE_OPTION: 'Do you want to this this option?',
  DELETE_CATEGORY: 'Do you want to delete this category?',
  DELETE_NOTE: 'Do you want to delete this note?',
  DELETE_MULTIPLE_NOTES: 'Do you want to delete all these selected notes?',
  DELETE_INTERESTED_SERVICE:
    'Do you want to delete this selected interested service?',
  RESTORE_LEAD: 'Do you want to restore',
  ARCHIVE_MULTIPLE_LEADS: 'Do you want to archive all these selected?',
  RESTORE_MULTIPLE_LEADS: 'Do you want to restore all these selected?',
  DELETE_MULTIPLE_LEADS: 'Do you want to delete all these selected?',
  DELETE_LEAD_FORM: 'Do you want to delete this lead form?',
  DELETE_ENQUIRY_FORM: 'Do you want to delete ',
  DELETE_BRANCH: 'Do you want to delete this Branch?',
  DELETE_SERVICE: 'Do you want to delete this Service?',
  DELETE_DEPARTMENT: 'Do you want to delete this Department?',
  DELETE_TASK: 'Do you want to delete this Task?',
  DELETE_EDUCATION_BACKGROUND: 'Do you want to delete this qualification -',
  DELETE_OTHER_TEST: 'Do you want to delete this other test?',
  DELETE_LANGUAGE_TEST_SCORE: 'Do you want to delete this language test score?',
  DELETE_IELTS_SCORE: 'Do you want to delete this IELTS score?',
  DELETE_TOEFL_SCORE: 'Do you want to delete this TOEFL score?',
  DELETE_PTE_SCORE: 'Do you want to delete this PTE score?',
  DELETE_DUOLINGO_SCORE: 'Do you want to delete this Duolingo score?',
  DELETE_DOCUMENT: 'Do you want to delete the document?',
  DELETE_APPOINTMENT: 'Do you want to delete the appointment?',
  DELETE_PROFESSIONAL_EXPERIENCE: 'Do you want to delete the experience?',
  DELETE_APPLICATION: 'Do you want to delete this Application?',
  DELETE_OTHER_DETAILS_CATEGORY:
    'Do you want to delete this other details category?',
  DISCONTINUE_APPLICATION: 'Do you want to Discontinue this Application?',
  COMPLETE_APPLICATION: 'The application has been completed',
  REVERT_APPLICATION: 'Do you want to Revert this Application?',
  GO_TO_PREVIOUS_STAGE: 'Do you want to go back to previous stage?',
  DELETE_WORKFLOW_TYPE: 'Do you want to delete this workflow type?',
  DELETE_WORKFLOW:
    'Do you want to delete this workflow? All associated data will be deleted.',
  DELETE_INSTITUTION:
    'Deleting this institution will delete all of its products as well. Are you sure you want to proceed?',
  START_APPLICATION: 'Do you want to start this application?',
  DELETE_ACADEMIC_REQUIREMENT:
    'Do you want to delete this academic requirement?',
  DELETE_SCORE: 'Do you want to delete this language score?',
  MAKE_MAIN_BRANCH: 'Do you want to make this main branch?',
  DOWNLOAD_DOCUMENT: 'Do you want to download this document?',
  DOWNLOAD_DOCUMENTS: 'Do you want to download these documents?',
  CONTACT_ARCHIVE_FINAL:
    'Archiving this contact will discontinue all of their applications.',
  DEACTIVATE_USER: 'Do you want to deactivate the user',
  ACTIVATE_USER: 'Do you want to activate the user',
};
export const PRODUCT_FEE_RESPONSE_MESSAGE = {
  FEE_NAME_ALREADY_EXISTS: 'Fee name already exists',
  ADD_SUCCESS: 'Fee added successfully',
  DELETE_SUCCESS: 'Fee deleted successfully',
  UPDATE_SUCCESS: 'Fee updated successfully',
  PRODUCT_FEE_NOT_FOUND: 'Product Fee Not Found',
  ERROR_MESSAGE: 'Invalid Data',
  PRODUCT_NOT_FOUND: 'Product Not Found',
  INVALID_FEE: 'Fee should be between 0 and 99,999,999',
  ENTER_FEE_AMOUNT: 'Please enter Fee Amount',
  UPDATE_FEE: 'Please update Fee Amount',
};
export const FEE_NAME_REQUIRED = 'Please enter Fee Option Name';
export const BRANCH_NAME_REQUIRED = 'Please enter Branch Name';
export const PRODUCT_NAME_REQUIRED = 'Please enter name';
export const FEE_NAME_MAX_LENGTH =
  'Fee Option Name must be up to 50 characters';
//CONFIRM TITLE
export const CONFIRM_TITLE = {
  ARCHIVE_LEAD: 'Archive ',
  ARCHIVE_MULTIPLE_LEADS: 'Archive Selected',
  RESTORE_LEAD: 'Restore ',
  RESTORE_MULTIPLE_LEADS: 'Restore Selected',
  DELETE_LEAD: 'Delete ',
  DELETE_NOTE: 'Delete Note',
  DELETE_PRODUCT: 'Delete Product',
  DISABLE_COMPANY: 'Disable Company',
  DELETE_COMPANY: 'Delete Company',
  REJECT_COMPANY: 'Reject Company',
  RESTORE_PRODUCT: 'Restore Product',
  DELETE_MULTIPLE_LEADS: 'Delete Selected',
  DELETE_DOCUMENT: 'Delete Document ',
  DELETE_APPOINTMENT: 'Delete Appointment ',
  DELETE_INSTITUTION: 'Delete Institution ',
  START_APPLICATION: 'Start Application',
  DELETE_ACADEMIC_REQUIREMENT: 'Delete Academic Requirement',
  DELETE_SCORE: 'Delete Language Score',
  MAKE_MAIN_BRANCH: 'Change Main Branch.',
  DOWNLOAD_DOCUMENT: 'Download Document ',
  DOWNLOAD_DOCUMENTS: 'Download Documents',
  DELETE_WORKFLOW_STAGE: 'Delete Workflow Stage',
};

export const NOTES_RESPONSE_MESSAGE = {
  DELETE_NOTE: 'Note deleted successfully',
  ADD_NOTE: 'Note added successfully',
  EDIT_NOTE: 'Note updated successfully',
  REQUIRED_TITLE: 'Please enter title',
  REQUIRED_DESCRIPTION: 'Please enter descriptions',
  MAX_CHARACTER_MESSAGE: 'Title must be up to 70 characters',
};

export const LANGUAGE_SCORE_RESPONSE = {
  ADD_SUCCESS: 'Language score added successfully',
  UPDATE_SUCCESS: 'Language score updated successfully',
  DELETE_SUCCESS: 'Language score deleted successfully',
  ERROR_MESSAGE: 'Invalid Data',
  NOT_FOUND: 'Language score not found',
};
export const INACTIVE_LEAD_FORM = 'This form is inactive';

export const BRANCH_RESPONSE_MESSAGE = {
  BRANCH_NOT_FOUND: 'Branches cannot be found.',
  BRANCH_NAME_ALREADY_EXISTS: 'Branch name already exists.',
  ALL_BRANCHES: 'All Branches',
  SPECIFIC_BRANCH_NOT_FOUND: 'Branch with this id cannot be found.',
  SPECIFIC_BRANCH_MAIN_BRANCH:
    'This is main branch. Please change the main branch before deleting.',
  BRANCH_GENERATE: 'Branch generated successfully.',
  BRANCH_NOT_CREATED: 'Oops!! Branch cannot be created.',
  BRANCH_UPDATE: 'Branch updated successfully.',
  BRANCH_UPDATE_FAILED: 'Branch cannot be updated.',
  BRANCH_DELETE: 'Branch deleted.',
  CANNOT_DELETE_BRANCH_WITH_CONTACTS: 'Cannot delete branch with contacts',
  CANNOT_DELETE_BRANCH_WITH_USERS:
    'Please delete the users or assign them to a different office first',
  BRANCH_DELETE_FAILED: 'Branch deletion failed.',
  DELETE_NOT_ALLOWED:
    'Branches with associated users, contacts or applications cannot be deleted.',
};

export const DEPARTMENT_RESPONSE_MESSAGE = {
  DEPARTMENT_NOT_FOUND: 'Departments cannot be found.',
  ALL_DEPARTMENTS: 'All Departments',
  SPECIFIC_DEPARTMENT_NOT_FOUND: 'Department with this id cannot be found.',
  DEPARTMENT_GENERATE: 'Department created successfully.',
  DEPARTMENT_NOT_CREATED: 'Oops!! Department cannot be created.',
  DEPARTMENT_UPDATE: 'Department updated successfully.',
  DEPARTMENT_UPDATE_FAILED: 'Department cannot be updated.',
  DEPARTMENT_DELETE: 'Department deleted.',
  DEPARTMENT_DELETE_FAILED: 'Department deletion failed.',
};

export const UPLOAD_RESPONSE_MESSAGE = {
  IMAGE_TYPE: 'You can only upload JPG/PNG file!',
  IMAGE_SIZE: 'Image must smaller than 2MB!',
  CANNOT_UPLOAD: 'Image not uploaded',
};

export const DOCUMENT_RESPONSE_MESSAGE = {
  UPLOAD_SUCCESS: 'Document Uploaded Successfully',
  DOWNLOAD_LINK: 'Document Download link.',
  UPDATE_SUCCESS: 'Document Name Updated successfully.',
  DELETE_SUCCESS: 'Document Deleted successfully.',
  NOT_FOUND: 'Document not found.',
  ALL_LEAD_DOCUMENTS: 'List of all lead documents',
};

export const EDUCATION_BACKGROUND_RESPONSE_MESSAGE = {
  ADD_EDUCATION_BACKGROUND: 'Education background added successfully.',
  ADD_EDUCATION_BACKGROUND_FAILED: 'Education background not added.',
  ALL_EDUCATION_BACKGROUND_DATA:
    'Education backgrounds retrieved successfully.',
  EDUCATION_BACKGROUND_NOT_FOUND: 'Education background not found.',
  EDUCATION_BACKGROUND: 'Education background found.',
  EDUCATION_BACKGROUND_DELETED: 'Education background deleted successfully.',
  EDUCATION_BACKGROUND_NOT_DELETED: 'Education background not deleted.',
  EDUCATION_BACKGROUND_UPDATED: 'Education background updated successfully.',
  EDUCATION_BACKGROUND_NOT_UPDATED: 'Education background not updated.',
  MAXIMUM_VALUE_EXCEED_ERROR: 'GPA score should not be greater than 10',
  MINIMUM_VALUE_EXCEED_ERROR: 'GPA score should not be less than 0',
  SECURED_GPA_GREATER_THAN_TOTAL_GPA:
    'Scored GPA should be less than total GPA',
  ADD_EDUCATION_BACKGROUND_LEVELS_FAILED:
    'Education background level not added.',
  EDUCATION_BACKGROUND_LEVELS_NOT_FOUND:
    'Education background level not found.',
  ALL_EDUCATION_BACKGROUND_LEVELS: 'Education Background  Levels.',
  ADD_ACADEMIC_REQUIREMENTS_FAILED: 'Failed to save academic requirements',
  ADD_ACADEMIC_QUALIFICATION: 'Academic qualification added successfully!',
  DELETE_SUCCESS: 'Education background deleted successfully!',
  UPDATE_SUCCESS: 'Education background updated successfully!',
};

export const TASKS_RESPONSE_MESSAGE = {
  ADD_TASK: 'Task added successfully',
  UPDATE_TASK: 'Task updated successfully',
  ADD_TASKS_FAILED: 'Task not added.',
  DELETE_TASK: 'Task deleted successfully',
};
export const LEAD_PROFESSIONAL_EXPERIENCE_RESPONSE_MESSAGE = {
  ADD_LEAD_PROFESSIONAL_EXPERIENCE:
    'Professional experience added successfully.',
  UPDATE_LEAD_PROFESSIONAL_EXPERIENCE:
    'Professional experience updated successfully.',
  ADD_LEAD_PROFESSIONAL_EXPERIENCE_FAILED: 'Professional experience not added.',
  ALL_LEAD_PROFESSIONAL_EXPERIENCE_DATA:
    'Professional experiences retrieved successfully.',
  LEAD_PROFESSIONAL_EXPERIENCE_NOT_FOUND: 'Professional experience not found.',
  LEAD_PROFESSIONAL_EXPERIENCE: 'Professional experience found.',
  LEAD_PROFESSIONAL_EXPERIENCE_DELETED:
    'Professional experience deleted successfully.',
  LEAD_PROFESSIONAL_EXPERIENCE_NOT_DELETED:
    'Professional experience not deleted.',
  LEAD_PROFESSIONAL_EXPERIENCE_UPDATED:
    'Professional experience updated successfully.',
  LEAD_PROFESSIONAL_EXPERIENCE_NOT_UPDATED:
    'Professional experience not updated.',
  START_DATE_FUTURE: 'Start date cannot be in the future.',
  END_DATE_FUTURE: 'End date cannot be in the future.',
  END_DATE_LESS_THAN_START_DATE: 'End date can not be less than start date',
  INVALID_CONTACT_ID: 'Contact professional experience not found.',
};

export const APPLICATION_RESPONSE_MESSAGE = {
  APPLICATION_NOT_FOUND: 'Application not found.',
  APPLICATION_DETAIL: 'Application Detail.',
  LIST_APPLICATIONS: 'List of Applications',
  APPLICATION_GENERATE: 'Application created successfully.',
  APPLICATION_NOT_CREATED: 'Oops!! Application cannot be created.',
  APPLICATION_UPDATE: 'Application updated successfully.',
  APPLICATION_COMPLETED: 'Application completed Successfully.',
  APPLICATION_DISCONTINUED: 'Application discontinued.',
  APPLICATION_REVERTED: 'Application reverted back to In-Progress.',
  APPLICATION_UPDATE_FAILED: 'Application cannot be updated.',
  APPLICATION_DELETE: 'Application Deleted.',
  ALREADY_EXISTS: 'Application Already Exists.',
  STAGES_NOT_DEFINED:
    'Application Stage with selected workflow is  not defined.',
  APPLICATION_COMMENT_CREATED: 'Application Comment Created',
  APPLICATION_ACTIVITY_RETRIEVED: 'Application activities retrieved.',
  LIST_APPLICATION_COMMENT: 'Application comments retrieved.',
  INVALID_APPLICATION_STAGE: 'Workflow application stage not found.',
  APPLICATION_ASSIGNEE_UPDATED: 'Application assignee updated Successfully.',
  ASSIGNEE_REQUIRED: 'An application must have at least one assignee',
};

export const APPLICATION_STAGE_RESPONSE_MESSAGE = {
  APPLICATION_STAGES_NOT_FOUND: 'Application Stage not found.',
  LIST_APPLICATION_STAGES: 'List of Application Stages',
  APPLICATION_STAGE_CREATE: 'Application Stage created successfully.',
  APPLICATION_STAGE_NOT_CREATED: 'Oops!! Application Stage cannot be created.',
  APPLICATION_STAGE_UPDATE: 'Application Stage updated successfully.',
  APPLICATION_STAGE_UPDATE_FAILED: 'Application Stage cannot be updated.',
  APPLICATION_STAGE_DELETE: 'Application Stage Deleted.',
};

export const SERVICE_RESPONSE_MESSAGE = {
  NAME_REQUIRED: 'Please enter service name',
  DESCRIPTION_REQUIRED: 'Please enter descriptions',
  MAX_CHARACTER_MESSAGE: 'Service name must be up to 50 characters only',
  SERVICE_NOT_FOUND: 'Service not found.',
  LIST_SERVICES: 'List of Services',
  LIST_COUNTRIES_FOR_SERVICES: 'List of Countries',
  SERVICE_GENERATE: 'Service generated successfully.',
  SERVICE_NOT_CREATED: 'Oops!! Service cannot be created.',
  SERVICE_UPDATE: 'Service updated successfully.',
  SERVICE_UPDATE_FAILED: 'Service cannot be updated.',
  ALREADY_EXIST: 'Service Already Exists.',
  SYNC_SUCCESS: 'Service Imported Successfully!',
  SYNC_FAILED: 'Failed to sync the service !!',
  SYNC_ALREADY: 'Service has already been imported.',
  SERVICE_DELETE_FAILED: 'Service deletion failed.',
  SERVICE_DELETED: 'Service deleted.',
  SERVICE_ID_NOT_PROVIDED: 'Please enter service id to sync',
  SERVICE_IN_USE: 'Service can not be deleted. It is already in use.',
  SERVICE_DELETE_DENIED:
    'Service has workflow type or contact visit so it can not be deleted.',
  SERVICE_NOT_IMPORTED: 'Services not imported. Please Import Services first',
};

export const APPOINTMENT_MESSAGE = {
  APPOINTMENT_RETRIEVED: 'All appointments retrieved.',
  APPOINTMENT_SAVE: 'Appointment added successfully!!',
  APPOINTMENT_DELETE: 'Appointment deleted successfully!!',
  APPOINTMENT_UPDATE: 'Appointment updated successfully!!',
  APPOINTMENT_STATUS: 'Appointment status changed successfully!!',
  NO_APPOINTMENT: 'No appointments found',
};

export const OPTION_MESSAGE = {
  OPTION_ADD_SUCCESS: 'Options added successfully!',
  OPTION_GET_SUCCESS: 'Option retrieved successfully',
  OPTION_DELETE_SUCCESS: 'Option deleted successfully',
  OPTION_UPDATE_SUCCESS: 'Option updated successfully',
};

export const CHECKLIST_RESPONSE_MESSAGE = {
  CHECKLIST_NOT_FOUND: 'Checklist not found.',
  LIST_CHECKLISTS: 'List of Checklists',
  CHECKLIST_CREATE: 'Checklist created successfully.',
  CHECKLIST_NOT_CREATED: 'Oops!! Checklist cannot be created.',
  CHECKLIST_UPDATE: 'Checklist updated successfully.',
  CHECKLIST_UPDATE_FAILED: 'Checklist cannot be updated.',
  CHECKLIST_DELETE: 'Checklist Item Deleted.',
  CHECKLIST_DELETE_FAILED: 'Checklist cannot be deleted.',
};

export const FORM_RESPONSE_MESSAGE = {
  FORM_NOT_FOUND: 'Workflow form item not found.',
  FORM_DOES_NOT_EXIST: "Form doesn't exist",
  UPDATE_SUCCESS: 'Form updated successfully',
  LIST_FORM: 'List of workflow form',
  ERROR_ON_PARSE: 'Error while parsing Ids',
  WORKFLOW_FORM_DELETE: 'Workflow form id deleted',
};

export const WORKFLOW_CHECKLIST_RESPONSE_MESSAGE = {
  LIST: 'List of Workflow  Application Stage checklist.',
  LIST_NOT_FOUND: 'Workflow  checklist  not  found.',
  CHECKLIST_CREATED: 'Workflow  Application Stage checklist created.',
};

export const WORKFLOW_STAGES_RESPONSE_MESSAGE = {
  LIST: 'List of Workflow  Application Stages.',
  LIST_NOT_FOUND: 'Workflow  Application Stages  not  found.',
  STAGE_CREATED: 'Workflow  Application Stage created.',
  UPDATE: 'Workflow  Application Stage updated.',
  ALREADY_EXIST: 'Workflow  Application Stage Already Exists.',
  DELETE: 'Workflow  Application Stage Deleted.',
  NOT_FOUND: 'Workflow Application Stage Not found',
  WORKFLOW_NOT_FOUND: 'Provided workflow not found',
  RESTRICT_ACTION: 'User is not authorized to perform this action.',
};

export const WF_CHECKLIST_DOCUMENT_RESPONSE_MESSAGE = {
  CREATED: 'Document successfully created.',
  LIST: 'Checklist Document Lists',
  UPDATE: 'Checklist Document updated successfully',
  NOT_FOUND: 'Checklist document not found',
};

export const LEAD_OTHER_DETAILS = {
  ADD_SUCCESS: 'Fields added successfully',
  DELETE_SUCCESS: 'Field Deleted Successfully',
  UPDATE_SUCCESS: 'Fields updated successfully',
  OTHER_DETAIL_CATEGORY: 'Category added successfully',
  OTHER_DETAIL_VALUE: 'Value Entered successfully!',
  OTHER_DETAIL_VALUE_UPDATE: 'Value Updated successfully!',
  CATEGORY_UPDATE_SUCCESS: 'Category Updated Successfully!',
  CATEGORY_RETRIEVED: 'All Categories retrieved successfully!',
  CATEGORY_DELETE: 'deleted successfully!',
  CATEGORY_EXIST: 'Category already exist!',
  LABEL_EXIST: 'Label already exist!',
  INVALID_WORKFLOW_FORM_ID: 'Workflow form id is invalid.',
  EMPTY_WORKFLOW_FORM_ID: "Workflow don't have other detail fields.",
};

export const TASK_RESPONSE_MESSAGE = {
  TASK_NOT_FOUND: 'Tasks not found.',
  ALL_TASKS: 'List of all Tasks',
  SPECIFIC_TASK_NOT_FOUND: 'Task with this id cannot be found.',
  CONTACT_REQUIRED: 'Contact is required.',
  TASK_GENERATE: 'Task created successfully.',
  TASK_NOT_CREATED: 'Oops!! Task cannot be created.',
  TASK_UPDATE: 'Task updated successfully.',
  TASK_STATUS_UPDATE: 'Status updated successfully.',
  TASK_UPDATE_FAILED: 'Task cannot be updated.',
  TASK_DELETE: 'Task deleted.',
  TASK_DELETE_FAILED: 'Task deletion failed.',
  TASK_ASSIGNEE_UPDATE: 'Task assignee updated successfully.',
};

export const WORKFLOW_TYPE_RESPONSE_MESSAGE = {
  LIST: 'List of Workflow types',
  LIST_NOT_FOUND: 'Workflow  types  not  found.',
  CREATED: 'Workflow type created.',
  UPDATED: 'Workflow type updated.',
  DELETED: 'Workflow type Deleted.',
  ALREADY_EXISTS: 'Workflow type Already Exist',
  NOT_DELETED: 'Workflow Type cannot be deleted.',
  SERVICE_NOT_EXISTS: 'Provided service does not exist.',
  WORKFLOW_NOT_EXISTS: 'Provided workflow does not exist.',
};

export const BRANCH_RESPONSE = {
  UPDATE_SUCCESS: 'Updated successfully!',
  MAIN_BRANCH_CHANGE: 'Do you want to change main branch?',
};

export const WORKFLOW_RESPONSE_MESSAGE = {
  LIST: 'List of Workflows',
  LIST_NOT_FOUND: 'Workflows not found.',
  NOT_FOUND: 'Workflow not  found.',
  DETAIL: 'Workflow detail',
  CREATED: 'Workflow created.',
  ALREADY_EXISTS: 'Workflow Already Exist',
  UPDATED: 'Workflow updated.',
  DELETED: 'Workflow Deleted.',
  SERVICE_NOT_EXISTS: 'Provided service does not exist.',
  WORKFLOW_TYPE_NOT_EXISTS: 'Provided workflow type does not exist.',
  NOT_DELETED: 'Workflow cannot be deleted.',
};

export const VALIDATION_MESSAGE = {
  SELECT_SERVICE: 'Please Select Service.',
  SELECT_WORKFLOW_TYPE: 'Please Select Workflow Type.',
  SELECT_COUNTRY: 'Please Select Country.',
  INVALID_ZIP_CODE: 'Invalid Zip Code',
  INVALID_POSTAL_CODE: 'Invalid Postal Code',
  INVALID_TEXT_INPUT: 'Please enter text only',
  INVALID_NUMBER_INPUT: 'Please enter numbers only',
  INVALID_PHONE_NUMBER: 'Invalid Phone Number',
  INVALID_PASSPORT_NUMBER: 'Invalid Passport Number',
  INVALID_VISA_TYPE: 'Invalid Visa Type',
  INVALID_EMAIL: 'Please input valid email.',
  SELECT_CONTACT: 'Please select contact.',
  INVALID_TAX_NUMBER: 'Invalid Tax Number.',
};

export const VISIT_PURPOSE_RESPONSE_MESSAGE = {
  GET_ONE: 'Visit Purpose based on id',
  LIST: 'List of Visit Purposes',
  LIST_SERVICE: 'List of Visit Purposes based on service id',
  LIST_NOT_FOUND: 'Visit Purposes not found.',
  NOT_FOUND: 'Visit Purpose not found.',
  CREATED: 'Visit purpose created.',
  UPDATED: 'Visit purpose updated.',
  DELETED: 'Visit purpose Deleted.',
  RESTRICT_GET_PURPOSE: 'User is not authorized to access visit purpose.',
  RESTRICT_ADD_PURPOSE: 'User is not authorized to add visit purpose.',
  RESTRICT_EDIT_PURPOSE: 'User is not authorized to edit visit purpose.',
  RESTRICT_DELETE_PRODUCT: 'User is not authorized to delete visit purpose.',
};

export const CONTACT_VISIT_RESPONSE_MESSAGE = {
  GET_ONE: 'Office visit based on id',
  NOT_FOUND: 'Office visit not found',
  LIST: 'List of all office visits',
  LIST_NOT_FOUND: 'Office visits not found',
  PREVIOUS_VISIT_NOT_FOUND: 'No previous visit found',
  CREATED: 'Office visit created',
  RESTRICT_ADD_CONTACT_VISIT: 'User is not authorized to add office visit',
  RESTRICT_GET_CONTACT_VISIT: 'User is not authorized to fetch office visits',
  LIST_OF_PREVIOUS_VISITS: 'List of previous visits',
  UPDATED: 'Office visit updated',
  NO_SESSION_NOTE: 'No session note available',
};

export const CONTACT_RESPONSE_MESSAGE = {
  CREATED: 'Contact created successfully!',
  LIST: 'List of all contacts',
  CONTACT_NOT_FOUND: 'Contact not found.',
  EMAIL_ALREADY_EXISTS: 'Contact with email already exists',
  CONTACT_DELETE: 'Contact deleted successfully',
  UPDATED: 'Contact updated successfully',
  CANNOT_DELETE_CONTACT_WITH_APPLICATIONS:
    'Contacts with applications cannot be deleted',
  CANNOT_ARCHIVE_CONTACT_WITH_APPLICATIONS:
    'Contacts with applications cannot be archived',
  NOT_AUTHORIZED_TO_VIEW:
    'You do not have the permission to access this Contact',
};

export const DATA_RESPONSE_MESSAGE = {
  UPDATED: 'Data updated successfully',
  DELETED: 'Data deleted successfully',
};

export const LEAD_RESPONSE_MESSAGE = {
  CREATED: 'Lead created successfully!',
  LEAD_DELETE: 'Lead deleted successfully.',
  LEAD_UPDATED: 'Lead updated successfully.',
  LEAD_ARCHIVED: 'Lead archived successfully.',
  LEAD_UNARCHIVED: 'Lead restored successfully.'

};

export const CLIENT_RESPONSE_MESSAGE = {
  CLIENT_ARCHIVED: 'Client archived successfully.',
  CLIENT_UNARCHIVED: 'Client restored successfully.',
  CLIENT_UPDATED: 'Client updated successfully.',
  CLIENT_DELETED: 'Client deleted successfully.'
}

export const CONTACT_VISIT_ACTIVITY_LOG_RESPONSE_MESSAGE = {
  CREATED: 'Contact visit activity log created.',
  LIST: 'List of contact visit activity logs',
};

export const AGENT_PARTNER_MESSAGE = {
  CREATED: 'Agent Partner created successfully',
  ONLY_ONE_MAIN_BRANCH_ALLOWED: 'Only one main branch is allowed',
  INVALID_AGENT_TYPE: 'Invalid agent type',
  AGENT_NOT_FOUND: 'Agent not found',
  UPDATED: 'Agent Partner updated successfully',
};

export const CONTACT_ENQUIRY_MESSAGE = {
  FORM_NOT_FOUND: 'Contact enquiry not found.',
  NOT_FOUND: 'Contact enquiry not found.',
  SUB_TYPE_NOT_FOUND: 'Product sub type not found.',
  SERVICE_NOT_FOUND: 'Service not found',
  STATUS_UPDATED: 'Contact enquiry status updated successfully',
  DELETED: 'Enquiry form deleted successfully.',
  DELETE_FAILED: 'Failed to delete Enquiry form. Please try again.',
  UPDATED: 'Enquiry form updated successfully.',
  CREATED: 'Enquiry form created successfully.',
  FORM_ALREADY_USED: 'Lead form already used and cannot be deleted.',
  REJECTED_SUCCESSFULLY: 'Contact enquiry rejected successfully.',
  APPROVED_SUCCESSFULLY: 'Contact enquiry approved successfully.',
};
