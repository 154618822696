import { useMutation, useQueryClient } from "@tanstack/react-query"
import { updateNote } from '@crm/services.api';
import { UpdateNotePayload } from "@model/crm/note.model";
import { errorNotificationHandler, successNotificationHandler } from "@moxie/shared";
import { AxiosError } from "axios";

const useUpdateNote = () => {

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ noteId, ...body }: UpdateNotePayload) => updateNote(noteId, body),
    onSuccess(_, payload) {
      switch (payload.updatedField) {
        case 'task':
          successNotificationHandler('Task updated successfully');
          break
        case 'task-created':
          successNotificationHandler('Task created successfully');
          break
        default:
          successNotificationHandler('Note updated successfully');
      }
      queryClient.invalidateQueries({
        queryKey: ['notes'],
      });
      queryClient.invalidateQueries({
        queryKey: ['task-count'],
      });
    },
    onError(error: AxiosError<any> | unknown) {
      if (error instanceof AxiosError) {
        return errorNotificationHandler(error.response?.data?.title);
      }
      errorNotificationHandler("Something went wrong!");
    },
  })
}

export default useUpdateNote;
