/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Col, Collapse, DatePicker, Divider, Row } from 'antd';
import {
  IProfessionalExperience,
  IForm,
  IGetPopupContainer,
} from '@shared-components/models';
import Form from 'antd/lib/form';
import {
  GUTTER,
  TEXT,
  LABEL,
  PLACEHOLDER,
  COUNTRIES,
  COUNTRY_LABEL,
  COUNTRY_PLACEHOLDER,
  EMPLOYMENT_TYPE,
  INDUSTRY,
  regex,
  PHONE_NUMBER_INVALID,
} from '@moxie/constants';
import {
  FormItem,
  Input,
  Option,
  SearchableSelect,
  TextArea,
} from 'libs/shared/src/shared';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import moment, { Moment } from 'moment';
import { CaretRightOutlined } from '@ant-design/icons';
const { Panel } = Collapse;

const ProfessionalExperienceForm: React.FC<IForm<IProfessionalExperience>> = ({
  form,
  onSubmit,
  initialData,
  setDisabled,
}: IForm<IProfessionalExperience>) => {
  const [isStillWorking, setIsStillWorking] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState(['organization']);

  useEffect(() => {
    if (initialData && Object.keys(initialData).length > 0) {
      initialData.start_date = changeDateToMoment(initialData.start_date);
      initialData.end_date = changeDateToMoment(initialData.end_date);
      initialData.start_and_end_date = [
        initialData.start_date,
        initialData.end_date,
      ];
      const initialValue = { ...initialData };
      form.setFieldsValue(initialValue);
    } else form.resetFields();
  }, []);

  useEffect(() => {
    if (initialData?.is_still_working) {
      setIsStillWorking(initialData.is_still_working);
    }
  }, []);

  const changeDateToMoment = (
    date?: string | Moment | null
  ): Moment | undefined => {
    if (date) return moment(date);
    return;
  };

  const handleIsStillWorking = () => {
    form.resetFields(['start_date', 'start_and_end_date']);
    setIsStillWorking(!isStillWorking);
  };

  const handleDateDisable = (currentDate: any) => {
    const customDate = moment().format('YYYY-MM-DD');
    return currentDate && currentDate >= moment(customDate, 'YYYY-MM-DD');
  };

  const handleSubmit = async (data: IProfessionalExperience) => {
    if (data.start_date) data.start_date = moment(data.start_date).toString();
    if (data.start_and_end_date) {
      data.start_date = moment(data.start_and_end_date[0]).toString();
      data.end_date = moment(data.start_and_end_date[1]).toString();
    }
    if (isStillWorking) data.end_date = null;
    data.is_still_working = isStillWorking;
    delete data['start_and_end_date'];
    onSubmit && onSubmit(data);
  };

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };
  const handleSubmitFormFailed = (errors: any) => {
    setDisabled(true);
    const errorFields = errors.errorFields;
    const errorFieldNames = errorFields.map((el: any) => el.name[0]);
    errorFieldNames.map((item: any) => {
      const panelKey = item.split('_')[0];
      setActiveKey([panelKey]);
      if (item === 'start_and_end_date' || item === 'start_date') {
        setActiveKey(['organization']);
      }
    });
  };

  const handleOptionFilter = (input: string, option: any): boolean =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      form={form}
      onFieldsChange={onFieldsChange}
      onFinishFailed={handleSubmitFormFailed}
    >
      <div>
        <div className="form_section_header">{TEXT.GENERAL_DETAILS}</div>
        <Row gutter={GUTTER}>
          <Col span="24">
            <Form.Item
              name="job_title"
              label={LABEL.JOB_TITLE}
              rules={[{ required: true, max: 50, type: 'string' }]}
            >
              <Input placeholder={PLACEHOLDER.JOB_TITLE} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={GUTTER}>
          <Col span="12">
            <div id="professional_experience_type" className="relative">
              <FormItem
                name="job_type"
                label={LABEL.EMPLOYMENT_TYPE}
                rules={[{ required: true }]}
              >
                <SearchableSelect
                  placeholder={PLACEHOLDER.EMPLOYMENT_TYPE}
                  filterOption={handleOptionFilter}
                  getPopupContainer={(): IGetPopupContainer =>
                    document.getElementById('professional_experience_type')
                  }
                >
                  {EMPLOYMENT_TYPE.map((employment) => {
                    return (
                      <Option value={employment.value} key={employment.value}>
                        {employment.name}
                      </Option>
                    );
                  })}
                </SearchableSelect>
              </FormItem>
            </div>
          </Col>
          <Col span="12">
            <div id="professional_industry_type" className="relative">
              <FormItem name="industry" label={LABEL.INDUSTRY}>
                <SearchableSelect
                  placeholder={PLACEHOLDER.INDUSTRY}
                  filterOption={handleOptionFilter}
                  getPopupContainer={(): IGetPopupContainer =>
                    document.getElementById('professional_industry_type')
                  }
                >
                  {INDUSTRY.map((industry) => {
                    return (
                      <Option value={industry.value} key={industry.id}>
                        {industry.name}
                      </Option>
                    );
                  })}
                </SearchableSelect>
              </FormItem>
            </div>
          </Col>
        </Row>
      </div>
      <Collapse
        activeKey={activeKey}
        bordered={false}
        ghost={true}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        onChange={(newActiveKey: any) => setActiveKey(newActiveKey)}
      >
        <Panel
          header={<span>{TEXT.ORGANIZATION_DETAILS}</span>}
          key="organization"
          forceRender={true}
        >
          <Row gutter={GUTTER}>
            <Col span="24">
              <FormItem
                name="organization_name"
                label={LABEL.ORGANIZATION_NAME}
                rules={[{ required: true, type: 'string', max: 50 }]}
              >
                <Input placeholder={PLACEHOLDER.ORGANIZATION_NAME} />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span="12">
              <div id="professional_country_list" className="relative">
                <FormItem name="country" label={COUNTRY_LABEL}>
                  <SearchableSelect
                    placeholder={COUNTRY_PLACEHOLDER}
                    filterOption={handleOptionFilter}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('professional_country_list')
                    }
                  >
                    {COUNTRIES.map((country) => {
                      return (
                        <Option value={country.name} key={country.code}>
                          {country.name}
                        </Option>
                      );
                    })}
                  </SearchableSelect>
                </FormItem>
              </div>
            </Col>
            <Col span="12">
              <FormItem name="city" label={LABEL.CITY}>
                <Input placeholder={PLACEHOLDER.CITY} />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={24}>
              <FormItem name="is_still_working" valuePropName="checked">
                <Checkbox onChange={handleIsStillWorking}>
                  {LABEL.STILL_WORKING}
                </Checkbox>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            {isStillWorking !== true && (
              <Col span="12">
                <div id="professional_end_date" className="relative">
                  <Form.Item
                    name="start_and_end_date"
                    label={LABEL.SELECT_JOB_PERIOD}
                    rules={[{ required: true }]}
                  >
                    <DatePicker.RangePicker
                      className="full-width"
                      picker="month"
                      disabledDate={handleDateDisable}
                      getPopupContainer={(): IGetPopupContainer =>
                        document.getElementById('professional_end_date')
                      }
                    />
                  </Form.Item>
                </div>
              </Col>
            )}
            {isStillWorking && (
              <Col span="12">
                <div id="professional_start_date" className="relative">
                  <Form.Item
                    name="start_date"
                    label={LABEL.START_DATE}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      disabledDate={handleDateDisable}
                      picker="month"
                      className="full-width"
                      getPopupContainer={(): IGetPopupContainer =>
                        document.getElementById('professional_start_date')
                      }
                    />
                  </Form.Item>
                </div>
              </Col>
            )}
          </Row>
        </Panel>
        <Panel
          header={<span>{TEXT.REFERENCE_DETAILS}</span>}
          key="reference"
          forceRender={true}
        >
          <Row gutter={GUTTER}>
            <Col span="12">
              <FormItem
                name="reference_name"
                label={LABEL.REFERENCE_NAME}
                rules={[{ required: true }]}
              >
                <Input placeholder={PLACEHOLDER.REFERENCE_NAME} />
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem
                name="reference_position"
                label={LABEL.REFERENCE_POSITION}
                rules={[{ required: true }]}
              >
                <Input placeholder={PLACEHOLDER.REFERENCE_POSITION} />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span="12">
              <FormItem
                name="reference_phone"
                label={LABEL.REFERENCE_PHONE}
                rules={[
                  {
                    pattern: new RegExp(regex.ONLY_NUMBER),
                    message: PHONE_NUMBER_INVALID,
                  },
                ]}
              >
                <Input placeholder={PLACEHOLDER.REFERENCE_PHONE} />
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem
                name="reference_email"
                label={LABEL.REFERENCE_EMAIL}
                rules={[{ type: 'email' }]}
              >
                <Input placeholder={PLACEHOLDER.REFERENCE_EMAIL} />
              </FormItem>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Form>
  );
};

export { ProfessionalExperienceForm };
