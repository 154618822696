import { Task, TaskStatus } from '@model/crm/note.model';
import { List } from 'antd';
import Tooltip from 'antd/es/tooltip';
import React from 'react';
import TaskTitle from './task-title';
import Typography from 'antd/es/typography';
import { UserAvatarGroup } from '@crm/src/shared/user-avatar-group';
import { popConfirm } from '@shared-components/elements';
import useUpdateTask from '../hooks/useUpdateTask';
import moment from 'moment';
import { useQueryClient } from '@tanstack/react-query';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';


interface Props {
  task: Task;
  onClick: (val: string) => void;
}
const TaskListItem: React.FC<Props> = ({ task, onClick }) => {
  const queryClient = useQueryClient();

  const updateTaskMutation = useUpdateTask();
  const updateStatus = (taskId: string, status: TaskStatus) => {
    const message = status === 'done' ? 'Are you sure you want to mark this task as done?' : 'Are you sure you want to revert this task?'
    popConfirm(message, '', async () => {
      await updateTaskMutation.mutateAsync({
        taskId,
        status,
        completedAt: status === 'done' ? moment().toISOString() : null,
      });
      queryClient.invalidateQueries({
        queryKey: ['tasks'],
      });
      queryClient.invalidateQueries({
        queryKey: ['task-count'],
      });
    })
  }
  return (
    <List.Item className="task-list-item" onClick={() => onClick(task.id)}>
      {task.status === 'done' ? (
        <Tooltip title="Mark as Incomplete">
          <div className="crm-document-checked task-dashboard-marker" onClick={(e) => {
            e.stopPropagation();
            updateStatus(task.id, 'pending')
          }}>
            <CheckOutlined />
          </div>
        </Tooltip>
      ) : (
        <Tooltip title="Mark as completed">
          <div className="blank-circle task-dashboard-marker" onClick={(e) => {
            e.stopPropagation();
            updateStatus(task.id, 'done')
          }} />
        </Tooltip>
      )}
      <div style={{ 'flexGrow': 1 }}>
        <TaskTitle task={task} />
        <Typography.Text type="secondary">
          <CalendarOutlined />
          <span> Due: {moment(task.dueAt).format('DD/MM/YYYY')}</span>
        </Typography.Text>

      </div>

      <div className="task-dashboard-assignees-wrapper">
        {task.note?.assignees.length ? (
          <div className="task-dashboard-assignees-list">
            <Typography.Text type="secondary">Tagged:</Typography.Text>
            <UserAvatarGroup userList={task.note?.assignees} />
          </div>
        )
          : null
        }
        <div className="task-dashboard-assignees-list">
          <Typography.Text type="secondary">Created by:</Typography.Text>
          {task?.createdBy && <UserAvatarGroup userList={[task?.createdBy]} />}
        </div>
      </div>

    </List.Item>
  )
}

export default TaskListItem
