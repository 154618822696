import React from 'react';
import { Typography } from 'antd';
import Collapse from 'antd/es/collapse';
import ScrollableTasks from './scrollable-tasks';
import moment from 'moment';
import { useTaskCount } from '../hooks/useTaskCount';

const TaskWidget = () => {
  const { overdueCount, todayCount, thisWeekCount, upcomingCount, completedCount } = useTaskCount();

  function getPanelHeader(label: string) {
    switch (label) {
      case "Overdue":
        return overdueCount === undefined ? label : `${label} (${overdueCount})`;
      case "Today":
        return todayCount === undefined ? label : `${label} (${todayCount})`;
      case "This Week":
        return thisWeekCount === undefined ? label : `${label} (${thisWeekCount})`;
      case "Upcoming":
        return upcomingCount === undefined ? label : `${label} (${upcomingCount})`;
      case "Completed":
        return completedCount === undefined ? label : `${label} (${completedCount})`;
      default:
        return "";
    }
  }

  const taskTabs = {
    'Overdue': <ScrollableTasks
      scrollableDivId="overdue-tasks"
      endDate={moment().subtract('day', 1).format('YYYY-MM-DD')}
      status="pending"
    />,
    'Today': <ScrollableTasks
      scrollableDivId="today-tasks"
      startDate={moment().format('YYYY-MM-DD')}
      endDate={moment().format('YYYY-MM-DD')}
      status="pending"
    />,
    'This Week': <ScrollableTasks
      scrollableDivId="this-week-tasks"
      startDate={moment().startOf('week').format('YYYY-MM-DD')}
      endDate={moment().endOf('week').format('YYYY-MM-DD')}
      status="pending"
    />,
    'Upcoming': <ScrollableTasks
      scrollableDivId="today-tasks"
      startDate={moment().add('week', 1).startOf('week').format('YYYY-MM-DD')}
      status="pending"
    />,
    'Completed': <ScrollableTasks
      scrollableDivId="completed-tasks"
      status="done"
    />,
  }
  return (
    <div className="dashboard-widget">
      <Typography.Title level={4}>
        Tasks
      </Typography.Title>
      <Collapse className="task-collapsable">
        {Object.entries(taskTabs).map(([label, comp]) => (
          <Collapse.Panel key={label} header={getPanelHeader(label)}>
            {comp}
          </Collapse.Panel>
        ))}
      </Collapse>

    </div>
  )
}

export default TaskWidget;
