import { Assignee } from '@model/application';
import { Note, NoteType, Task, TaskFormFields } from '@model/crm/note.model';
import { capitalizeTrimWord, errorNotificationHandler, popConfirm } from '@moxie/shared';
import TimelineItem from 'antd/lib/timeline/TimelineItem';
import React, { useContext, useEffect, useState } from 'react';
import { useTipTapEditor } from '../editor';
import moment from 'moment';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import SaveOutlined from '@ant-design/icons/SaveOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { useUpdateNote } from './hooks';
import useDeleteNote from './hooks/useDeleteNote';
import NoteForm from './note-form';
import NoteFormProvider, { NoteFormContext } from './note-form-provider';
import difference from 'lodash/difference';
import { Accordion, AccordionContent, AccordionHeader, AccordionItem } from '../accordion';
import RightOutlined from '@ant-design/icons/RightOutlined';

interface Props {
  createdBy: Assignee;
  key: string;
  createdAt: string;
  description: string;
  task?: Task;
  noteId: string;
  assignees: string[];
  contactId?: string;
  type: string;
}

export const NoteCards = ({ notes, type, contactId }: { notes: Note[], type: NoteType, contactId: string }) => {
  return (
    <>
      <Accordion
        className="activities-accordion"
        type="single"
      >
        {
          notes.map((item) => (
            <AccordionItem value={item.id} key={item.id}>
              <NoteFormProvider>
                <NoteCard
                  key={item.id}
                  createdBy={item.createdBy}
                  description={item.description}
                  createdAt={item.createdAt}
                  noteId={item.id}
                  task={item.task}
                  assignees={item.assignees.map(user => user.id)}
                  contactId={contactId}
                  type={type}
                />
              </NoteFormProvider>
            </AccordionItem>
          ))
        }
      </Accordion>
    </>
  )
}

const NoteCard: React.FC<Props> = ({ description, createdBy, key, createdAt, noteId, assignees, task, contactId, type }) => {
  const editor = useTipTapEditor(description, type);
  const updateNote = useUpdateNote();
  const deleteNote = useDeleteNote(noteId);
  const { noteMode, setNoteMode, validateForm } = useContext(NoteFormContext);

  const [form] = Form.useForm<TaskFormFields>();
  const [isInitial, setInitial] = useState(false);

  useEffect(() => {
    if (task) {
      setInitial(true)
      const dueDate = moment(task.dueAt);
      form.setFieldsValue({
        dueTime: dueDate,
        dueAt: dueDate,
        dueDateLabel: 'custom date',
        type: task.type,
        createTask: true,
      })
      setInitial(false)
    }
    else {
      setInitial(true);
      const dueDate = moment().add('days', 1);
      form.setFieldsValue({
        dueDateLabel: 'tomorrow',
        type: 'to do',
        createTask: false,
        dueAt: dueDate,
      })
      setInitial(false)

    }
  }, [task, form]);

  const onSubmit = async (values: TaskFormFields) => {
    if (!editor) {
      return;
    }
    await validateForm(editor, form, values, async (content, updatedAssignees) => {
      let updatedField = "note";
      if (values.createTask) {
        updatedField = 'task';
      }
      if (!task && values.createTask) {
        updatedField = 'task-created';
      }
      const removeAssignees = difference(assignees, updatedAssignees)
      setNoteMode('save');
      await updateNote.mutateAsync({
        updatedField,
        noteId: noteId,
        assignees: updatedAssignees,
        description: content,
        removeAssignees,
        ...(values.createTask ? {
          task: {
            contactId,
            dueAt: values?.dueAt?.toISOString(),
            type: values?.type,
          },
        } : {}),
      }).catch(() => {
        errorNotificationHandler('Something went wrong! Please try again');
        setNoteMode(undefined)
      });
      setNoteMode(undefined);
    })
  }

  const onDeleteSuccess = () => setNoteMode(undefined);
  const removeNote = () => {
    if (task?.id) {
      return popConfirm(
        'Are you sure?',
        'Associated task will also be deleted',
        () => {
          setNoteMode('delete');
          deleteNote.mutate(true, {
            onSuccess: () => onDeleteSuccess(),
          });
        },
        true
      )
    }
    setNoteMode('delete');
    return deleteNote.mutate(false, {
      onSuccess: () => onDeleteSuccess(),
    })
  }

  if (isInitial) {
    return null;
  }
  return (
    <TimelineItem key={key}>
      <AccordionHeader>
        <div>
          <RightOutlined className="margin-right-2 rotate-right-to-down" />
          <span className="font-weight-600">Note created by {capitalizeTrimWord(`${createdBy.firstName} ${createdBy.lastName}`)}</span>
        </div>
        <span className="muted-text">
          {moment(createdAt).format('dddd, MMMM D, YYYY')}
        </span>
      </AccordionHeader>
      <AccordionContent>
        <Form form={form} onFinish={onSubmit}>
          <NoteForm
            form={form}
            editor={editor}
            task={task}
            actions={
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                  disabled={noteMode !== undefined}
                  loading={noteMode === 'save'}
                >
                  Save
                </Button>

                <Button
                  danger
                  htmlType="button"
                  icon={<DeleteOutlined />}
                  onClick={removeNote}
                  disabled={noteMode !== undefined}
                  loading={noteMode === 'delete'}
                >
                  Delete
                </Button>
              </>
            }
          />
        </Form>
      </AccordionContent>
    </TimelineItem>
  )
}

export default NoteCard;
