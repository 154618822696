import { createNote } from "@crm/services.api";
import { CreateNotePayload, NoteType } from "@model/crm/note.model";
import { errorNotificationHandler, successNotificationHandler } from "@moxie/shared";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

const useCreateNote = (type: NoteType, refId: string) => {

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: CreateNotePayload) => createNote(type, refId, body),
    onSuccess(_, variables) {
      queryClient.invalidateQueries({
        queryKey: ['notes'],
      })
      if (variables?.task) {
        return successNotificationHandler('Note and task created successfully');
      }
      successNotificationHandler('Note created successfully');
    },
    onError(error: AxiosError<any> | unknown) {
      if (error instanceof AxiosError) {
        return errorNotificationHandler(error.response?.data?.title);
      }
      errorNotificationHandler("Something went wrong!");
    },
  })
}

export default useCreateNote;
