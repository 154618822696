import React, { useState } from 'react';
import Select from 'antd/es/select';
import { Empty, type SelectProps } from 'antd';
import { CrmPaginatedResponse } from '@model/api-response.model';
import { useSearchBox } from '@crm/libs/hooks';
import Spin from 'antd/es/spin';
import { TEXT } from '@moxie/constants';

interface Props<T> extends SelectProps<any> {
  // eslint-disable-next-line no-unused-vars
  fetchQuery?: (params: Record<string, unknown>) => Promise<CrmPaginatedResponse<T>>;
  valueKey?: keyof T;
  labelKey?: keyof T;
  filterValues?: string[];
  notApplicable?: { label: string; value: string };
  emptyOption?: React.ReactElement;
  filter?: Record<string, unknown>;
  // eslint-disable-next-line no-unused-vars
  children?: (option: T) => JSX.Element;
  hideDefaultOptionView?: boolean;
}

function SearchBox<T>({
  fetchQuery,
  labelKey,
  valueKey,
  children,
  filter,
  hideDefaultOptionView = false,
  emptyOption,
  filterValues,
  ...props
}: React.PropsWithChildren<Props<T>>) {

  const [search, setSearch] = useState<string>('');
  const { onSearch, options, optionSelectMap, paginateOptions, isLoading, initialLoading } = useSearchBox({
    fetchQuery,
    search,
    setSearch: setSearch,
    valueKey,
    labelKey,
    value: props.value,
    filter,
    hideDefaultOptionView,
    filterValues,
  });

  if (initialLoading) {
    return <Spin spinning />;
  }

  return (
    <Select
      showSearch
      defaultOpen
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={onSearch}
      options={optionSelectMap}
      onPopupScroll={paginateOptions}
      loading={isLoading}
      disabled={initialLoading}
      onBlur={() => setSearch('')}
      notFoundContent={(<Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={TEXT.NO_DATA}
      />
      )}
      {...props}
    >
      {emptyOption}
      {
        children &&
        options.map((option, index) => (
          <React.Fragment key={index}>{children(option)}</React.Fragment>
        ))
      }
    </Select >
  );
}

export default SearchBox;
